import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { NavLink, } from "react-router-dom";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { Scrollbars } from "react-custom-scrollbars";
import { Accordion, Card } from "react-bootstrap";
import store from "../../store";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: window.innerWidth > 768 ? true : false,
      activeKey: this.props.menuKey ? this.props.menuKey : "0",
    };
  }
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };
  componentDidMount() {
    window.addEventListener("resize", this.changestate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.changestate);
  }

  changestate = (e) => {
    this.setState({ show: this.state.show ? false : true });
  };

  render() {
    const { user } = this.props.auth.user;
    const userData = store.getState();

    return (
      <div className="sidebar">
        <button className="btn" onClick={this.changestate} id="menu-toggle">
          <FontAwesomeIcon icon={faList} />
        </button>
        {this.state.show ? (
          <div className="border-right">
            <div className="list-group list-group-flush">
              <Scrollbars style={{ width: 250, height: "88.5vh" }}>
                {this.props.auth.user.moderator == "1" ? (
                  <div>
                    <NavLink
                      to="/users"
                      className="list-group-item list-group-item-action"
                    >
                      Users
                    </NavLink>
                    {/* <NavLink to="/chat" className="list-group-item list-group-item-action">Chat</NavLink> */}
                  </div>
                ) : (
                  <div>
                    <Accordion defaultActiveKey={this.state.activeKey}>
                      {userData && userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/dashboard"
                          className="list-group-item list-group-item-action"
                        >
                          Dashboard
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.User == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/users"
                          className="list-group-item list-group-item-action"
                        >
                          Users
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {/*<NavLink to="/users" className="list-group-item list-group-item-action">Users</NavLink>*/}
                      {userData.auth.user.UserBalance == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/Userbalance"
                          className="list-group-item list-group-item-action"
                        >
                          User Balance
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.Kyc == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/kyc"
                          className="list-group-item list-group-item-action"
                        >
                          KYC
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.Currency == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/currency"
                          className="list-group-item list-group-item-action"
                        >
                          Currency
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/AdminController"
                          className="list-group-item list-group-item-action"
                        >
                          Admin Controller
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.SpotPair == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/Pairmanagement"
                          className="list-group-item list-group-item-action"
                        >
                          Spot Pair
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.SpotOrderHistory == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/orderhistory"
                          className="list-group-item list-group-item-action"
                        >
                          Spot Order History
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.SpotTradeHistory == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/tradehistory"
                          className="list-group-item list-group-item-action"
                        >
                          Spot Trade History
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.p2ppair == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/P2PPairmanagement"
                          className="list-group-item list-group-item-action"
                        >
                          P2P Pair
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.p2ptradehistory == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/p2ptrade"
                          className="list-group-item list-group-item-action"
                        >
                          P2P Trade History
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.p2pdisputelist == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/p2pdispute"
                          className="list-group-item list-group-item-action"
                        >
                          P2P Dispute Trade
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.p2pcommissionhistory == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/adminrevenue"
                          className="list-group-item list-group-item-action"
                        >
                          P2P Commision History
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.Staking == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/staking"
                          className="list-group-item list-group-item-action"
                        >
                          Staking
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.stackingorder == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/history/stakeOrder"
                          className="list-group-item list-group-item-action"
                        >
                          Staking Order
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.stackingsettlement == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/history/stakeSettlement"
                          className="list-group-item list-group-item-action"
                        >
                          Staking Settlement
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.DepositList == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/deposit"
                          className="list-group-item list-group-item-action"
                        >
                          Deposit List
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {/*  <NavLink to="/Bonus" className="list-group-item list-group-item-action">Bonus</NavLink>*/}
                      {userData.auth.user.WithdrawList == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/withdraw"
                          className="list-group-item list-group-item-action"
                        >
                          Withdraw List
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.SupportCategory == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/support-category"
                          className="list-group-item list-group-item-action"
                        >
                          Support Category
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.Support == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/support"
                          className="list-group-item list-group-item-action"
                        >
                          Support
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.airdrop == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/airdrop"
                          className="list-group-item list-group-item-action"
                        >
                          AirDrop
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.airdropHistory == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/airdroplist"
                          className="list-group-item list-group-item-action"
                        >
                          AirDrop History
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.feeandrefferal == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/feesowntoken"
                          className="list-group-item list-group-item-action"
                        >
                          Fees & Referral
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.refferalcommisonhistory == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/referralcommission"
                          className="list-group-item list-group-item-action"
                        >
                          Referral Commission History
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.Launchpad == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/launchpad"
                          className="list-group-item list-group-item-action"
                        >
                          Launchpad
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.BlogCategory == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/category"
                          className="list-group-item list-group-item-action"
                        >
                          Blog Category
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.BlogArticle == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/article"
                          className="list-group-item list-group-item-action"
                        >
                          Blog Article
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {/* <NavLink to="/perpetual" className="list-group-item list-group-item-action">Perpetual contract</NavLink> */}
                      {/*<NavLink to="/staking" className="list-group-item list-group-item-action">Staking</NavLink>*/}

                      {userData.auth.user.ContactUs == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/contactus"
                          className="list-group-item list-group-item-action"
                        >
                          Contact Us
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.Newsletter == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/newsletter"
                          className="list-group-item list-group-item-action"
                        >
                          Newsletter
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.Announcement == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/anouncement"
                          className="list-group-item list-group-item-action"
                        >
                          Announcement
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.PriceConversation == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/priceCNVlist"
                          className="list-group-item list-group-item-action"
                        >
                          Price Conversion
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.FaqCategory == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/faq-category"
                          className="list-group-item list-group-item-action"
                        >
                          FAQ Category
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.Faq == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/faq"
                          className="list-group-item list-group-item-action"
                        >
                          FAQ
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {/* <NavLink to="/chat" className="list-group-item list-group-item-action">Chat</NavLink> */}
                      {userData.auth.user.EmailTemplate == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/emailtemplates"
                          className="list-group-item list-group-item-action"
                        >
                          Email Templates
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.CmsPage == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <Card>
                          <Accordion.Toggle as={Card.Header} eventKey="1">
                            CMS Management
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body>
                              {/* <NavLink
                                to="/cms"
                                className="list-group-item list-group-item-action"
                              >
                                CMS Pages All
                              </NavLink> */}
                              <NavLink
                                to="/cms/1"
                                className="list-group-item list-group-item-action"
                              >
                                Header One
                              </NavLink>
                              <NavLink
                                to="/cms/2"
                                className="list-group-item list-group-item-action"
                              >
                                Header Two
                              </NavLink>
                              <NavLink
                                to="/cms/3"
                                className="list-group-item list-group-item-action"
                              >
                                Header Three
                              </NavLink>
                              <NavLink
                                to="/cms/4"
                                className="list-group-item list-group-item-action"
                              >
                                Header Four
                              </NavLink>
                              <NavLink
                                to="/cms/5"
                                className="list-group-item list-group-item-action"
                              >
                                Header Five
                              </NavLink>
                              <NavLink
                                to="/cms/6"
                                className="list-group-item list-group-item-action"
                              >
                                Header Six
                              </NavLink>
                              <NavLink
                                to="/cms/slider"
                                className="list-group-item list-group-item-action"
                              >
                                Slider
                              </NavLink>
                              <NavLink
                                to="/cms/Footer"
                                className="list-group-item list-group-item-action"
                              >
                                Footer
                              </NavLink>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.LaunchpadCms == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/launchpadcms"
                          className="list-group-item list-group-item-action"
                        >
                          LaunchpadCms
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {/* <NavLink to="/liquidated" className="list-group-item list-group-item-action">Liquidated List</NavLink> */}

                      {/* {userData.auth.user.Language == true ||
                    userData.auth.user.role == "superadmin" ? (
                      <NavLink
                        to="/language"
                        className="list-group-item list-group-item-action"
                      >
                        Language
                      </NavLink>
                    ) : (
                      ""
                    )} */}

                      {/* {userData.auth.user.p2pchathistory == true ||
  userData.auth.user.role == "superadmin" ? (
                    <NavLink
                      to="/p2pchat"
                      className="list-group-item list-group-item-action"
                    >
                      P2P Chat History
                    </NavLink>
) : (
  ""
)} */}

                      {/* <NavLink to="/perpetual-Trade-History" className="list-group-item list-group-item-action">perpetual Trade History</NavLink>
                                            <NavLink to="/perpetual-Order-History" className="list-group-item list-group-item-action">perpetual Order History</NavLink> */}

                      {userData.auth.user.SideSetting == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/site-setting"
                          className="list-group-item list-group-item-action"
                        >
                          Site Setting
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.sitemap == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/site-map"
                          className="list-group-item list-group-item-action"
                        >
                          Site Map
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.deleteuserList == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/deleted-users-list"
                          className="list-group-item list-group-item-action"
                        >
                          Deleted User List
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.TradingBot == true ||
                        userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/Tradingbot"
                          className="list-group-item list-group-item-action"
                        >
                          Trading Bot
                        </NavLink>
                      ) : (
                        ""
                      )}
                    </Accordion>
                    {/* {userData.auth.user.TradingBot == true || userData.auth.user.role == "superadmin" ?
                                                <NavLink to="/Tradingbot" className="list-group-item list-group-item-action">Trading bot</NavLink>
                                                : ("")} */}
                    {/* <NavLink to="/closedpositions" className="list-group-item list-group-item-action">Closed positions</NavLink> */}
                    {/* <NavLink to="/feesettings" className="list-group-item list-group-item-action">Fee Settings</NavLink> */}
                    {/* <NavLink to="/category" className="list-group-item list-group-item-action">Blog Category</NavLink>
                                            <NavLink to="/article" className="list-group-item list-group-item-action">Blog Article</NavLink>
                                            <NavLink to="/HelpCentreCategory" className="list-group-item list-group-item-action">HelpCentreCategory</NavLink>
                                            <NavLink to="/subcategory" className="list-group-item list-group-item-action">Help Center Sub Category</NavLink>
                                            <NavLink to="/HelpCentreArticle" className="list-group-item list-group-item-action">Help Center Article</NavLink> */}
                  </div>
                )}
              </Scrollbars>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

Sidebar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Sidebar);