import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../config";
import { Modal, Button } from 'react-bootstrap/';
import * as moment from "moment";

const url = keys.API_URL;
class Users extends Component {

    constructor(props) {
        super(props);


        this.columns = [
            {
                key: "created_date",
                text: "Date & Time",
                className: "date",
                align: "left",
                sortable: false,
                cell: record => {

                    const createddate = moment(record.created_date).format(
                        "DD-MM-YYYY  k:mm:s "
                    );
                    return createddate;
                }

            },
            {
                key: "_id",
                text: "Trade ID",
                className: "userId",
                align: "left",
                sortable: false,

            },
            {
                key: "sellerEmail",
                text: "Buyer Email",
                className: "userId",
                align: "left",
                sortable: false,
                // cell: record => {
                //     const email = record.BuyorSell == "Buy" ? record.sellerEmail:record.BuyorSell == "Sell" ? record.sellerEmail:""
                //     return email;
                // }

            },
            {
                key: "buyerEmail",
                text: "Seller Email",
                className: "userId",
                align: "left",
                sortable: false,
                // cell: record => {
                //     const email = record.BuyorSell == "Buy" ? record.buyerEmail :record.BuyorSell == "Sell"? record.buyerEmail:""
                //     return email;
                // }

            },

            {
                key: "BuyorSell",
                text: "Post Type",
                className: "userId",
                align: "left",
                sortable: false,

            },

            {
                key: "currencyPair",
                text: "Currency Pair",
                className: "userId",
                align: "left",
                sortable: false

            },

            {
                key: "price",
                text: "Price",
                className: "userId",
                align: "left",
                sortable: false
            },

            {
                key: "fiat_amount",
                text: "Fiat Amount",
                className: "userId",
                align: "left",
                sortable: false,

            },

            {
                key: "crypto_amount",
                text: "Total Crypto",
                className: "userId",
                align: "left",
                sortable: false,
            },

            {
                key: "fee",
                text: "Fee",
                className: "fee",
                align: "left",
                sortable: false,
                cell: record => {

                    const createddate = moment(record.created_date).format(
                        "DD-MM-YYYY  k:mm:s "
                    );
                    return (
                        <>{record.fee && record.fee}</>);
                }


            },



            {
                key: "status",
                text: "Status",
                className: "userId",
                align: "left",
                sortable: false,

            },

            //               {
            //                 key: "action",
            //                 text: "Action",
            //                 className: "action",
            //                 width: 100,
            //                 align: "left",
            //                 sortable: false,
            //                 cell: record => {
            //                     return (
            //                         <Fragment>

            // <button title="View"

            //                                className="btn btn-primary btn-sm"
            //                                onClick={() => this.viewrecord(record)}
            //                                style={{marginRight: '5px'}}>
            //                                <i className="fa fa-eye"></i>
            //                            </button>

            //                              {/* <button title="View Message"

            //                                 className="btn btn-primary btn-sm"
            //                                 onClick={() => this.editRecord(record)}
            //                                 style={{marginRight: '5px'}}>
            //                                 <i className="fa fa-comment"></i>
            //                             </button>

            //                             <button title="Log" title="Dispute"
            //                                className="btn btn-danger btn-sm"
            //                                onClick={() => this.editRecord(record)}
            //                                style={{marginRight: '5px'}}>
            //                                <i className="fa fa-gavel"></i>
            //                            </button> */}




            //                             {/* <button
            //                                 className="btn btn-danger btn-sm mr-1"  
            //                                 onClick={() => this.deleteRecord(record)}>
            //                                 <i className="fa fa-trash"></i>
            //                             </button>
            //                              <button 
            //                                className="btn btn-primary btn-sm mr-1"
            //                                 onClick={() => this.changestatus(record)}>
            //                                 <i className={lockVal}></i>
            //                             </button>
            //                             {/* <button title="Moderator"
            //                                className="btn btn-primary btn-sm mr-1"
            //                                 onClick={() => this.changemoderator(record)}>
            //                                 <i className="fa fa-stack-exchange"></i>
            //                             </button> */}
            // {/* 
            //                              <button title="Trade Status"
            //                                className="btn btn-primary btn-sm mr-1"
            //                                 onClick={() => this.BlockTrade(record)}>
            //                                 <i class={tradetrue}></i>
            //                             </button>  */}

            //                              {/* <button title="Add bonus"
            //                                className="btn btn-primary btn-sm"
            //                                 onClick={() => this.chatblock(record)}>
            //                                 <i class="fa fa-gift"></i>
            //                             </button> */}
            //                         </Fragment>
            //                     );
            //                 }
            //             }



        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Buyhistory",
            no_data_text: 'No Record found!',
            sort: { column: "Created date", order: "desc" },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
            defaultSortAsc: true,
        };

        this.state = {
            currentRecord: {
                records: [],
                responsive: true,
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
        //   console.log(this.state,'statezzzzzz');
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        axios
            .get(url + "/adminApi/p2pTrade")
            .then(res => {
                this.setState({ records: res.data.data })
                console.log(res, 'reszzzzhhhhhh');
            })
            .catch()
    }
    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }


    viewrecord(record) {
        this.setState({ currentRecord: record });
        this.props.history.push("/p2ptradeview/" + record._id);

    }



    render() {
        return (
            <>

                <UserAddModal />
                <UserUpdateModal record={this.state.currentRecord} />
                <div id="page-content-wrapper">
                    <div className="container-fluid"  >

                        <h3 className="mt-2 text-secondary">P2P Trade List</h3>
                        <div style={{ overflow: 'scroll' }}>
                            <ReactDatatable
                                responsive={this.state.responsive}
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </>


        );
    }

}

Users.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(withRouter(Users));
