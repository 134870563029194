// import package
import React, { Component } from "react";
import Select from "react-select";
import { Modal, Form } from "react-bootstrap";
import clsx from 'classnames'

// import action
import { newBot } from '../../../actions/tradingBot'

// import lib
import { toastAlert } from '../../../lib/toastAlert'
import isEmpty from '../../../lib/isEmpty';
import validation from './validation';

const initialFormValue = {
    'pairId': '',
    'side': [],
    'buyStartPrice': '0',
    'buyEndPrice': '0',
    'sellStartPrice': '0',
    'sellEndPrice': '0',
    'startQuantity': '0',
    'endQuantity': '',
    'count': ''
}

const sideOption = [
    {
        label: "Buy",
        value: "buy",
    },
    {
        label: "Sell",
        value: "sell",
    },
]

class TradeBotModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pairList: [],
            formValue: initialFormValue,
            priceLoader: false,
            loader: false,
            errors: {}
        };

        this.handleSelect = this.handleSelect.bind(this)
    }

    componentDidMount() {
        const { pairList } = this.props;
        if (pairList && pairList.length > 0) {
            this.setState({ pairList })
        }
    }

    componentWillReceiveProps(nextProps) {
        const { pairList } = nextProps;
        const { priceLoader } = this.state;

        if (pairList && pairList.length > 0) {
            this.setState({ pairList })
        }

        if (priceLoader) {
            this.setState({ priceLoader: false })
        }
    }

    handleChange = e => {
        e.preventDefault();
        let { name, value } = e.target;
        let formData = { ...this.state.formValue, ...{ [name]: value } };
        this.setState({ formValue: formData });
    }

    handleSelect(selectedOption) {
        let formData = this.state.formValue;
        if (selectedOption && selectedOption.length > 0) {
            formData = {
                ...formData,
                side: selectedOption.map((el) => {
                    return el.value;
                })
            };
        } else {
            formData = {
                ...formData,
                side: selectedOption && selectedOption.map((el) => {
                    return el.value;
                })
            };
        }
        this.setState({ formValue: formData });
    }

    handleClose = () => {
        const { onHide } = this.props;
        onHide();
        this.setState({ 'formValue': initialFormValue, errors: {} });
    }

    handleSubmit = async e => {
        e.preventDefault();

        const { formValue } = this.state;
        const { refetch } = this.props;

        this.setState({ loader: true })

        try {
            let reqData = {
                'pairId': formValue.pairId,
                'side': formValue.side,
                'buyStartPrice': formValue.buyStartPrice,
                'buyEndPrice': formValue.buyEndPrice,
                'sellStartPrice': formValue.sellStartPrice,
                'sellEndPrice': formValue.sellEndPrice,
                'startQuantity': formValue.startQuantity,
                'endQuantity': formValue.endQuantity,
                'count': formValue.count
            }

            const validateError = await validation(reqData)
            console.log("-----validateError", validateError)
            if (!isEmpty(validateError)) {
                this.setState({ loader: false })
                this.setState({ "errors": validateError })
                return
            }

            const { status, loading, message, error } = await newBot(reqData);
            this.setState({ loader: false })
            if (status == 'success') {
                toastAlert('success', message, 'newBot')
                this.handleClose()
                refetch()
            } else {
                if (!isEmpty(error)) {
                    this.setState({ errors: error })
                } else {
                    toastAlert('error', message, 'newBot')
                }
            }
        } catch (err) { }
    };


    render() {
        const { pairId, side, buyStartPrice, buyEndPrice, sellStartPrice, sellEndPrice, startQuantity, endQuantity, count } = this.state.formValue
        const { errors, pairList, loader, priceLoader } = this.state;
        const { refetchPair, isShow } = this.props;

        let isMarkPrice = false, markPrice = 0;
        if (!isEmpty(pairId)) {
            let pairData = pairList.find(el => el._id == pairId);
            if (pairData) {
                isMarkPrice = true
                markPrice = pairData.markPrice
            }
        }

        return (
            <div>
                <Modal
                    show={isShow}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    size="lg"
                    centered
                    scrollable={true}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <h4 className="modal-title">Trading bot</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <form noValidate>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="copyright_text">Pair</label>
                                </div>
                                <div className="col-md-6">
                                    <Form.Control
                                        as="select"
                                        custom
                                        name={'pairId'}
                                        value={pairId}
                                        onChange={this.handleChange}
                                    >
                                        <option value={''}>{"Select Pair"}</option>
                                        {
                                            pairList && pairList.length > 0 && pairList.map((item, key) => {
                                                return (
                                                    <option key={key} value={item._id}>{item.firstCurrencySymbol + item.secondCurrencySymbol}</option>
                                                )
                                            })
                                        }
                                    </Form.Control>
                                    <span className="text-danger">{errors.pairId}</span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="copyright_text">Live Price</label>
                                </div>
                                <div className="col-md-4">
                                    {
                                        !isMarkPrice && "0.00000000"
                                    }
                                    {
                                        isMarkPrice && markPrice
                                    }
                                </div>
                                {
                                    isMarkPrice && <div className="col-md-3">
                                        <button
                                            onClick={() => {
                                                this.setState({ priceLoader: true })
                                                refetchPair();
                                            }}
                                            type="button"
                                            className="btn btn-primary"
                                            disabled={priceLoader}
                                        >
                                            {priceLoader && <i class="fas fa-spinner fa-spin"></i>}
                                            Refresh
                                        </button>
                                    </div>
                                }

                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="copyright_text">Side</label>
                                </div>
                                <div className="col-md-6">
                                    <Select
                                        value={
                                            sideOption && sideOption.length > 0
                                                ? sideOption.filter((el) => {
                                                    if (!isEmpty(side) && side.includes(el.value)) {
                                                        return el;
                                                    }
                                                })
                                                : []
                                        }
                                        isMulti
                                        name="colors"
                                        options={sideOption}
                                        onChange={this.handleSelect}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                    <span className="text-danger">{errors.side}</span>
                                </div>
                            </div>

                            {
                                side && side.includes('buy') && <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="buyStartPrice">Buy Start Price </label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            name="buyStartPrice"
                                            type="number"
                                            min="0"
                                            value={buyStartPrice}
                                            onChange={this.handleChange}
                                            error={errors.buyStartPrice}
                                            className={clsx("form-control", {
                                                invalid: errors.buyStartPrice
                                            })} />
                                        <span className="text-danger">{errors.buyStartPrice}</span>
                                    </div>
                                </div>

                            }

                            {
                                side && side.includes('buy') && <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="buyEndPrice">Buy End Price </label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            name="buyEndPrice"
                                            type="number"
                                            min="0"
                                            value={buyEndPrice}
                                            onChange={this.handleChange}
                                            error={errors.buyEndPrice}
                                            className={clsx("form-control", {
                                                invalid: errors.buyEndPrice
                                            })} />
                                        <span className="text-danger">{errors.buyEndPrice}</span>
                                    </div>
                                </div>
                            }

                            {
                                side && side.includes('sell') && <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="sellStartPrice">Sell Start Price</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            name="sellStartPrice"
                                            type="number"
                                            min="0"
                                            value={sellStartPrice}
                                            onChange={this.handleChange}
                                            error={errors.sellStartPrice}
                                            className={clsx("form-control", {
                                                invalid: errors.sellStartPrice
                                            })} />
                                        <span className="text-danger">{errors.sellStartPrice}</span>
                                    </div>
                                </div>
                            }

                            {
                                side && side.includes('sell') && <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="sellEndPrice">Sell End Price</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            name="sellEndPrice"
                                            type="number"
                                            min="0"
                                            value={sellEndPrice}
                                            onChange={this.handleChange}
                                            error={errors.sellEndPrice}
                                            className={clsx("form-control", {
                                                invalid: errors.sellEndPrice
                                            })} />
                                        <span className="text-danger">{errors.sellEndPrice}</span>
                                    </div>
                                </div>
                            }

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="startQuantity">Quantity range start</label>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        name="startQuantity"
                                        type="number"
                                        min="0"
                                        value={startQuantity}
                                        onChange={this.handleChange}
                                        error={errors.startQuantity}
                                        className={clsx("form-control", {
                                            invalid: errors.startQuantity
                                        })} />
                                    <span className="text-danger">{errors.startQuantity}</span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="endQuantity">Quantity range end</label>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        name="endQuantity"
                                        type="number"
                                        min="0"
                                        value={endQuantity}
                                        onChange={this.handleChange}
                                        error={errors.endQuantity}
                                        className={clsx("form-control", {
                                            invalid: errors.endQuantity
                                        })} />
                                    <span className="text-danger">{errors.endQuantity}</span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="count">Order count</label>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        name="count"
                                        type="number"
                                        min="0"
                                        value={count}
                                        onChange={this.handleChange}
                                        error={errors.count}
                                        className={clsx("form-control", {
                                            invalid: errors.count
                                        })} />
                                    <span className="text-danger">{errors.count}</span>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.handleClose}
                        >
                            Close
                        </button>
                        <button
                            onClick={this.handleSubmit}
                            className="btn btn-primary"
                            disabled={loader}
                        >
                            {loader && <i class="fas fa-spinner fa-spin"></i>}
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default TradeBotModal;