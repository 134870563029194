import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { GET_ERRORS, SET_CURRENT_USER, FORGOT, USER_LOADING } from "./types";

import {
  SET_AUTHENTICATION,
  REMOVE_AUTHENTICATION,
  USER_UPDATE,
} from "../constant";
import keys from "./config";

// import config
import axios from "../config/axios";

const url = keys.baseUrl;
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post(url + "api/user-add", userData)
    .then((res) => history.push("/login"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const loginUser = (userData) => (dispatch) => {
  axios
    .post("api/login", userData)
    .then((res) => {
      const { token } = res.data;
      // localStorage.setItem("jwtToken", token);
      localStorage.setItem("adminJwtToken", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.code,
      })
    );
};

export const forgotUser = (forgotData, history) => (dispatch) => {
  axios
    .post("adminapi/forgotPassword", forgotData)
    .then((res) => {
      dispatch({
        type: FORGOT,
        payload: res,
      });
      history.push("/login");
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data.errors,
      })
    );
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("adminJwtToken");
  localStorage.removeItem("admin_token");
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  // history.push('/login')
  // dispatch({
  //   type: REMOVE_AUTHENTICATION,
  //   authData: {
  //     isAuth: false,
  //   },
  // });
  // history.push('/login')

  // dispatch({
  //     type: SET_AUTHENTICATION,
  //     authData: {
  //         isAuth: false,
  //         userId: "",
  //     }
  // })
};

export const tfaFormsubmit = (otpData, history) => (dispatch) => {
  axios
    .post(url + "cryptoapi/tfa-enable", otpData)
    .then((res) =>
      dispatch({
        type: USER_UPDATE,
        payload: res,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};


export const resetPassword = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminapi/resetPassword`,
      data,
    });

    return {
      status: "success",
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
