// import lib
import isEmpty from './isEmpty';

export const dateTimeFormat = (dateTime, format = 'YYYY-MM-DD HH:mm') => {
    try {
        if (!isEmpty(dateTime)) {
            let newDateTime = new Date(dateTime);
            if (format.includes('YYYY')) {
                format = format.replace('YYYY', newDateTime.getFullYear())
            }

            if (format.includes('MM')) {
                let month = newDateTime.getMonth() + 1;
                month = month > 9 ? month : `0${month}`
                format = format.replace('MM', month)
            }

            if (format.includes('DD')) {
                let date = newDateTime.getDate();
                date = date > 9 ? date : `0${date}`
                format = format.replace('DD', date)
            }

            if (format.includes('HH')) {
                let hour = newDateTime.getHours();
                hour = hour > 9 ? hour : `0${hour}`
                format = format.replace('HH', hour)
            }

            if (format.includes('mm')) {
                let minute = newDateTime.getMinutes();
                minute = minute > 9 ? minute : `0${minute}`
                format = format.replace('mm', minute)
            }

            return format

        } else {
            return ''
        }
    } catch (err) {
        return ''
    }
}

export const UtcTimeToNormalTime = (dateTime, format = 'YYYY-MM-DD HH:mm:ss') => {

   let monthArray = ['January','Febraury','March','April','May','June','July','August','September','October','November','December']

   if(!isEmpty(dateTime)){
     
     let newDate = new Date(dateTime);
     
     let monthNumber = newDate.getMonth() + 1; // get Month Number
     let monthString = monthArray[newDate.getMonth()]; // get Month String
     let date = newDate.getDate();  // get Date
     let year = newDate.getFullYear(); // get Year
     let hour = newDate.getHours(); // get Hours
     let minutes = newDate.getMinutes(); // get Minutes
     let seconds = newDate.getSeconds(); // get Seconds
    
     // if (format.includes('a')) { // AM or PM
     //    let AM_PM = hour >= 12 ? 'pm' : 'am';
     //    format = format.replace('a',AM_PM)
     //    let AM_PM_HOUR = AM_PM == 'pm' ? hour - 12 : hour
     //    if (format.includes('HH')) { 
     //      format = format.replace('HH',AM_PM_HOUR)
     //    }
     // }

     if (format.includes('YYYY')) { // 2021
        format = format.replace('YYYY',year)
     }

     if (format.includes('MM')) { // 1 to 12 Months
        format = format.replace('MM',monthNumber)
     }

     if (format.includes('M')) { // January to December Months
        format = format.replace('M',monthString)
     }

     if (format.includes('DD')) { // 1 to 31 Day
        format = format.replace('DD',date)
     }

     if (format.includes('HH')) { // 1 to 12 Hours
        format = format.replace('HH',hour)
     }

     if (format.includes('mm')) { // 0 to 59 Minutes
        format = format.replace('mm',minutes)
     }

     if (format.includes('ss')) { // 0 to 59 Seconds
        format = format.replace('ss',seconds)
     }
     
     return format;
   }

   return '';
}
