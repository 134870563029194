import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import classnames from "classnames";
import $ from "jquery";
//import lib
import { toastAlert } from '../../lib/toastAlert';
import fileObjectUrl from '../../lib/fileObjectUrl'
import { CSVLink, CSVDownload } from "react-csv";
//import config
import config from '../../config/index';
import Papa from "papaparse"
// import action
import { downloadwithdrawlistcsvfile, bulkWithdraw } from '../../actions/siteSettingActions';
import isEmpty from "is-empty";

const initialFormValue = {
    "twiterLink": "",
    "fbLink": "",
    "linkedInLink": "",
    "telegramlink": "",
    "redditlink": "",
    "youtubelink": "",
    "emailLogo": "",
    "siteName": "",
    "address": "",
    "contactNo": "",
    "supportMail": "",
    "sampleFile": '',
}

const csvData = [
    ["email", "currencySymbol", "amount"],
    ["user@gmail.com", "ETH", "1"],
    ["user1@gmail.com", "BTC", "0.5"],
    ["user2@gmail.com", "INR", "100"]
];
class builkWithdraw extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formValue: initialFormValue,
            createObjectUrl: false,
            errors: {},
            sampleFile: '',
            loader: false.valueOf,
            csvfile: undefined

        };
        this.updateData = this.updateData.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.getsamplefile = this.getsamplefile.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        this.getsamplefile()
        const { records } = nextProps;
        if (records) {
            this.setState({
                formValue: {
                    twiterLink: records.twitterUrl,
                    fbLink: records.facebookLink,
                    linkedInLink: records.linkedinLink,
                    telegramlink: records.telegramlink,
                    redditlink: records.redditlink,
                    youtubelink: records.youtubelink,
                    emailLogo: records.emailLogo,
                    siteName: records.siteName,
                    address: records.address,
                    contactNo: records.contactNo,
                    supportMail: records.supportMail,
                }
            })
        }
    }
    async getsamplefile(e) {
        try {
            const { status, loading, message, error, file } = await downloadwithdrawlistcsvfile();
            this.setState({ loader: loading, createObjectUrl: false })
            if (status == 'success') {
                toastAlert('success', message, 'siteSettings')
                this.setState({ errors: {} })
                let url = file;
                let a = document.createElement('a');
                a.href = url;
                a.download = 'test.csv';
                a.click();

            } else if (status == 'failed') {
                if (error) {
                    this.setState({ errors: error })
                } else {
                    toastAlert('error', message, 'siteSettings')
                }
            }
        } catch (err) {
        }
    };
    handleChange = event => {
        if (event.target.files[0].size > 1000000) {
            toastAlert('error',
                "Image size should be less than  1MB",
                'siteSettings'
            );
            return false;
        } else {
            this.setState({
                csvfile: event.target.files[0]
            });
        }
    };

    importCSV = async () => {
        const { csvfile } = this.state;
        console.log("csvfilecsvfilecsvfile", csvfile)
        if (csvfile == undefined) {
            toastAlert('error', "Please Select .csv file", 'siteSettings')
            return
        }

        Papa.parse(csvfile, {
            complete: this.updateData,
            header: true
        });

    };

    async updateData(result) {
        var data = result.data;
        try {
            let depo = {
                bulkWithhdrawList: data
            }
            const { status, loading, message, error, file } = await bulkWithdraw(depo);
            this.setState({ loader: loading, createObjectUrl: false })
            if (status == 'success') {
                let url = file;
                let a = document.createElement('a');
                a.href = url;
                a.download = 'test.csv';
                a.click();
                toastAlert('success', message, 'siteSettings')
                this.setState({ errors: {}, csvfile: "" })
                $("#csv-input").val("")
            } else if (status == 'failed') {
                if (error) {
                    this.setState({ errors: error })
                } else {
                    toastAlert('error', message, 'siteSettings')
                }
            }
        } catch (err) {
        }
        console.log(data);
    }

    handleFile = (e) => {
        e.preventDefault();
        const { name, files } = e.target;
        let formData = { ...this.state.formValue, ...{ [name]: files[0] } };
        this.setState({ createObjectUrl: true, formValue: formData });
    };




    render() {
        const { errors, createObjectUrl } = this.state;

        const { twiterLink,
            fbLink,
            linkedInLink,
            telegramlink,
            redditlink,
            youtubelink,
            emailLogo,
            siteName,
            address,
            contactNo,
            supportMail
        } = this.state.formValue;

        return (
            <Card>
                <Card.Header>Bulk Withdraw</Card.Header>
                <Card.Body>


                    <div className="row mt-2">
                        <div className="col-md-3">
                            <label htmlFor="currencyName">Download Withdraw Request .csv File</label>
                        </div>
                        <div className="col-md-2">
                            <button className="btn btn-outline-primary float-right " onClick={this.getsamplefile}>Withdraw Request List  CSV</button>
                        </div>

                    </div>  {/* <CSVLink
         data={transactionData}
         filename='transactions.csv'
         className='hidden'
         ref={csvLink}
         target='_blank'
      /> */}


                    <div className="row mt-2">
                        <div className="col-md-3">
                            <label htmlFor="currencyName">Upload File</label>
                        </div>
                        <div className="col-md-9">
                            <input
                                className="csv-input"
                                type="file"
                                id="csv-input"
                                ref={input => {
                                    this.filesInput = input;
                                }}
                                name="file"
                                placeholder={null}
                                accept=".csv"
                                onChange={this.handleChange}
                            />

                            <span className="text-danger">{errors.filesInput}</span>

                        </div>
                        <div className="col-md-9">
                            <span >Only allow less than 1MB .csv file</span>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Button onClick={this.importCSV}>Bulk Withdraw</Button>
                </Card.Footer>
            </Card>
        )
    }
}

export default builkWithdraw;