import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { replyContact } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import { Editor } from "@tinymce/tinymce-react";
import "react-toastify/dist/ReactToastify.css";
class ContactUpdateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record._id,
            name: this.props.record.name,
            email: this.props.record.email,
            message: this.props.record.message,
            reply: this.props.record.reply,
            status: this.props.record.status,
            errors: {},
        };
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    handleEditorChange(reply, editor) {
        this.setState({ reply });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                name: nextProps.record.name,
                email: nextProps.record.email,
                message: nextProps.record.message,
                reply: nextProps.record.reply,
                status: nextProps.record.status,
            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
            });
        }

        if (
            nextProps.auth !== undefined &&
            nextProps.auth.replycontact !== undefined &&
            nextProps.auth.replycontact.data !== undefined &&
            nextProps.auth.replycontact.data.message !== undefined &&
            nextProps.auth.replycontact.data.success
        ) {
            $("#update-cms-modal").modal("hide");
            toast(nextProps.auth.replycontact.data.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            nextProps.auth.replycontact = "";
        }
    }

    onContactReplay = async (e) => {
        e.preventDefault();
        const newReply = {
            _id: this.state.id,
            reply: this.state.reply,
        };
        if (!this.state.reply) {
            toast("Please Enter Message", {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }

        const { status, loading, result, message } = await replyContact(newReply);
        if (status == "success") {
            toast(message, {
                position: toast.POSITION.TOP_CENTER,
            });
            this.setState({
                reply: "",
            });
            $("#update-contact-modal").modal("hide");
            setTimeout(() => {
                window.location.reload("/")
            }, 3000);
            
        }

    };

    render() {
        const { errors } = this.state;

        return (
            <div>
                <div className="modal fade" id="update-contact-modal" data-reset="true" data-keyboard="false" data-backdrop="static">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">
                                    Reply to contact
                                </h4>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                <form
                                    noValidate
                                    onSubmit={this.onContactReplay}
                                    data-dismiss="modal"
                                    id="update-contact"
                                >
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="contact-update-id"
                                        type="text"
                                        className="d-none"
                                    />
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            {this.state.name}
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Email</label>
                                        </div>
                                        <div className="col-md-9">
                                            {this.state.email}
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">
                                                Question
                                            </label>
                                        </div>
                                        <div className="col-md-9">
                                            {this.state.message}
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="reply">Reply</label>
                                        </div>
                                        <div className="col-md-9">
                                            {this.state.status ? (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.state
                                                            .reply,
                                                    }}
                                                />
                                            ) : (
                                                <>
                                                    <Editor
                                                        // apiKey="5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5"
                                                        apiKey="1rw95oj9pvwai59ksuyjjgyn2xd5lczf9z7k66qaqwgv6xhr"
                                                        tinymceScriptSrc="https://cdn.tiny.cloud/1/slwws02lp6bqf01tf5trn1lkzsox9o1vub33u0hbegttrbfr/tinymce/6/tinymce.min.js"
                                                        value={this.state.reply}
                                                        onEditorChange={
                                                            this
                                                                .handleEditorChange
                                                        }
                                                        init={{
                                                            height: 500,
                                                            menubar: false,
                                                            plugins: [
                                                                "advlist autolink lists link image charmap print preview anchor",
                                                                "searchreplace visualblocks code fullscreen",
                                                                "insertdatetime media table paste code help wordcount",
                                                            ],
                                                            toolbar:
                                                                "undo redo code | formatselect | bold italic backcolor | \
                                               alignleft aligncenter alignright alignjustify | \
                                               bullist numlist outdent indent | removeformat | help",
                                                        }}
                                                    />
                                                    <span className="text-danger">
                                                        {errors.reply}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                                {this.state.status == false &&
                                    <button
                                        form="update-contact"
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Reply
                                    </button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ContactUpdateModal.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps)(
    withRouter(ContactUpdateModal)
);