import React, { Component, Fragment } from "react";
import classnames from "classnames";
import DatePicker from "react-date-picker";
import moment from "moment";
import ReactDatatable from "@ashvin27/react-datatable";
import "react-toastify/dist/ReactToastify.css";

// import component
import { withRouter } from 'react-router-dom';

//import lib
import { toastAlert } from "../../lib/toastAlert";

// import action
import { anouncementAdd, getAnnouncementHistory } from "../../actions/anouncementAction";

const initialFormValue = {
    startDateTime: new Date(),
    endDateTime: new Date(),
    content: "",
};

class FaqPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formValue: initialFormValue,
            errors: {},
            totalCount: 0,
            records: [],
            offset: 0,
            pageSize: 10,
            sortOrder: {
                column: "createdAt",
                order: "desc"
            },
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);

        this.columns = [
            {
                key: "createdAt",
                text: "Created At",
                className: "createdAt",
                align: "left",
                sortable: false,
                width: "180",
                cell: (record) => {
                    return moment(record.createdAt).format("DD-MM-YYYY HH:mm")
                },
            },
            {
                key: "startDateTime",
                text: "Start Date",
                className: "startDateTime",
                align: "left",
                sortable: false,
                width: "120",
                cell: (record) => {
                    return moment(record.startDateTime).format("DD-MM-YYYY")
                },
            },
            {
                key: "endDateTime",
                text: "End Date",
                className: "endDateTime",
                align: "left",
                sortable: false,
                width: "120",
                cell: (record) => {
                    return moment(record.endDateTime).format("DD-MM-YYYY")
                },
            },
            {
                key: "content",
                text: "Content",
                className: "content",
                align: "left",
                sortable: false
            }
        ];

        this.config = {
            page_size: this.state.pageSize,
            sort: this.state.sortOrder,
            length_menu: [10, 20, 50],
            no_data_text: "No announcement found!",
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last",
                },
            },
            key_column: "_id",
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            pagination: "advance",
            show_info: true,
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData = async (filter = "") => {
        try {
            let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let data = {
                timezone,
                sortOrder: this.state.sortOrder,
                filter,
                limit: this.state.pageSize,
                offset: this.state.offset,
            };
            this.setState({ loading: true });
            let { status, result, totalCount } = await getAnnouncementHistory(data);
            if (status) {
                this.setState({ loading: false });
                this.setState({ records: [], totalCount: 0 }, function () {
                    this.setState({ totalCount, records: result });
                });
            }
        } catch (err) { }
    };

    handleChange(e) {
        e.preventDefault();
        let { name, value } = e.target;
        let formData = { ...this.state.formValue, ...{ [name]: value } };
        this.setState({ formValue: formData });
    }

    handleDateChange(name, date) {
        const formData = { ...this.state.formValue, ...{ [name]: date } };
        this.setState({ formValue: formData });
    }

    search = data => {
        let filter = data.filter_value;
        let pageSize = data.page_size;
        let offset = (data.page_number - 1) * pageSize;
        let sortOrder = data.sort_order ? data.sort_order : this.state.sortOrder;
        this.setState({ records: [], offset, pageSize, sortOrder, filter, loading: true }, function () {
            this.getData(filter);
        });
    }

    async handleSubmit(e) {
        try {
            e.preventDefault();

            const { formValue } = this.state;
            let reqData = formValue;
            const { status, loading, message, error } = await anouncementAdd(
                reqData
            );
            if (status == "success") {
                this.getData();
                this.setState({ formValue: initialFormValue, errors: {} });
                toastAlert("success", message, "anouncementAdd");
            } else {
                if (error) {
                    this.setState({ errors: error });
                }
                toastAlert("error", message, "anouncementAdd");
            }
        } catch (err) { }
    }

    search = data => {
        let filter = data.filter_value;
        let pageSize = data.page_size;
        let offset = (data.page_number - 1) * pageSize;
        let sortOrder = data.sort_order ? data.sort_order : this.state.sortOrder;
        this.setState({ records: [], offset, pageSize, sortOrder, filter, loading: true }, function () {
            this.getData(filter);
        });
    }

    render() {
        const { errors } = this.state;
        const { startDateTime, endDateTime, content } = this.state.formValue;
        return (
            <>


                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h3 className="mt-2 text-secondary">Announcement</h3>
                        <form noValidate onSubmit={this.handleSubmit}>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">
                                        Start Date
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <DatePicker
                                        format="MM-dd-y"
                                        value={startDateTime}
                                        minDate={new Date()}
                                        onChange={(date) =>
                                            this.handleDateChange(
                                                "startDateTime",
                                                date
                                            )
                                        }
                                    />
                                    <div className="text-danger">
                                        {errors.startDateTime}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="answer">End Date</label>
                                </div>
                                <div className="col-md-9">
                                    <DatePicker
                                        format="MM-dd-y"
                                        value={endDateTime}
                                        minDate={new Date()}
                                        onChange={(date) =>
                                            this.handleDateChange(
                                                "endDateTime",
                                                date
                                            )
                                        }
                                    />
                                    <div className="text-danger">
                                        {errors.endDateTime}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="content">Content</label>
                                </div>
                                <div className="col-md-9">
                                    <textarea
                                        onChange={this.handleChange}
                                        value={content}
                                        name="content"
                                        type="text"
                                        className={classnames(
                                            "form-control",
                                            {
                                                invalid: errors.content,
                                            }
                                        )}
                                    />

                                    <span className="text-danger">
                                        {errors.content}
                                    </span>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary"
                            >
                                Submit
                            </button>
                        </form>
                    </div>

                    <div className="container-fluid mt-5">
                        <h3 className="mt-2 text-secondary">
                            Announcement History
                        </h3>

                        <div style={{ overflow: 'scroll' }}>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={true}
                                onChange={this.search}
                                loading={this.state.loading}
                                total_record={this.state.totalCount}
                            />
                        </div>
                    </div>
                </div>
            </>

        );
    }
}

export default withRouter(FaqPage);