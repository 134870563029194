import React, { Component, Fragment } from "react";
import moment from "moment";
import ReactDatatable from "@ashvin27/react-datatable";

//import component
import { withRouter } from 'react-router-dom';
import { toastAlert } from "../../lib/toastAlert";

//impport action
import { getDeletedList } from "../../actions/userActions";

class deletedAccountList extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "createdAt",
                text: "DEleted date",
                className: "date",
                align: "left",
                sortable: false,
                cell: (record) => {
                    return (
                        <Fragment>
                            <p>
                                {moment(record.createdDate).format("MMMM,Do YYYY, hh:mm A")}
                            </p>
                        </Fragment>
                    );
                },
            },

            {
                key: "email",
                text: "Email",
                className: "tokenamount",
                align: "left",
                sortable: false,
            },

            {
                key: "phoneNo",
                text: "Phone No",
                className: "currency",
                align: "left",
                sortable: false,
            },

        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Perpetual",
            no_data_text: "No Contracts found!",
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last",
                },
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            loader: false,
            page: 1,
            limit: 10,
            count: 0,
            responsive: true,
        };

        this.getUserList = this.getUserList.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
    }

    componentDidMount() {
        const { page, limit } = this.state;
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let reqData = {
            page,
            limit,
            timezone
        };
        this.getUserList(reqData);
    }

    async getUserList(reqData) {
        try {
            this.setState({ loader: true });
            const { status, loading, result } = await getDeletedList(reqData);
            this.setState({ loader: loading });
            if (status == "success") {
                this.setState({ count: result.count, records: result.data });
            }
        } catch (err) { }
    }
    handlePagination(index) {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let reqData = {
            timezone,
            page: index.page_number,
            limit: index.page_size,
            search: index.filter_value,
        };
        this.getUserList(reqData);
        this.setState({
            page: index.page_number,
            limit: index.page_size,
            search: index.filter_value,
        });
    }
    addRecord() {
        this.props.history.push("/airdrop");
    }

    render() {
        const { count } = this.state;
        return (
            <>
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h3 className="mt-2 text-secondary">Deleted Users List </h3>

                        <ReactDatatable
                            responsive={this.state.responsive}
                            config={this.config}
                            records={this.state.records}
                            total_record={count}
                            dynamic={true}
                            columns={this.columns}
                            onChange={this.handlePagination}
                        />
                    </div>
                </div>
            </>

        );
    }
}

export default withRouter(deletedAccountList);
