import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import Select from "react-select";
import { connect } from "react-redux";
import { addLaunchpad } from "../../actions/LaunchpadActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Editor } from '@tinymce/tinymce-react';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import isEmpty from '../../lib/isEmpty';
import fileObjectUrl from '../../lib/fileObjectUrl';
import "react-datepicker/dist/react-datepicker.css";


const options = [
  { value: '1', label: 'ERC20' },
  { value: '2', label: 'BEP20' },
  { value: '3', label: 'TRC20' },
];

class LaunchpadAddModal extends React.Component {

    constructor(props) {
        super();
        this.state = {
            tokenName: "",
            // tokenType: "",
            symbol: "",
            availableCurrency:[],
            price: "",
            minAmt: "",
            discount:"",
            // availableSale:"",
            maxSupply:"",
            industry:"",
            website:"",
            content:"",
            image:"",
            imageUrl:"",
            whitePaper:"",
            whitePaperUrl:"",
            telegramLink:"",
            twitterLink:"",
            linkedinLink:"",
            redditLink:"",
            youtubeLink:"",
            imageerror:"",
            curimagesize:0,
            instagramLink:"",
            facebookLink:"",
            startDate:new Date(),
            endDate:new Date(),
            errors: {},
            contractAddress:"",
            decimals:8
        };
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);

    }
    handleChange(date) {
     this.setState({
     startDate: date
     })
      
     }
    handleChange1(date1) {
  
        this.setState({
     endDate: date1
     })
     }

    handleOnChange = (selectedOption) => {
      if(selectedOption &&  selectedOption.length > 0){
          let data = selectedOption.map(function(item) {
              return item['value'];
          });  
          this.setState({'availableCurrency': data});
      }
    }; 

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        console.log("retJsonObj",nextProps.auth)
        if (nextProps.auth !== undefined
            && nextProps.auth.Contactus !== undefined
            && nextProps.auth.Contactus.data !== undefined
            && nextProps.auth.Contactus.data.retMsg !== undefined) {
            $('#add-launch-modal').modal('hide');
            toast(nextProps.auth.Contactus.data.retMsg, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.newuser = undefined;
        }
    }


    handleEditorChange(content, editor) {
       this.setState({ content });
    }

    onChange = e => {
        let cleareError={...this.state.errors,[e.target.id]:""}
        this.setState({errors:cleareError})
        this.setState({ [e.target.id]: e.target.value });
    };
    
    handleChangeefile = (event) => {
        if(event.target.files[0]){
            var filesize= event.target.files[0].size
            this.setState({
              curimage: URL.createObjectURL(event.target.files[0]),
              [event.target.name]: event.target.files[0],
            });
        }

        if(event.target.name=="image"){
            this.setState({imageUrl:fileObjectUrl(event.target.files[0])})
        }else if(event.target.name=="whitePaper"){
            this.setState({whitePaperUrl:fileObjectUrl(event.target.files[0])})
        }

    };

    onLaunchpadAdd = async (e) => {
        e.preventDefault();

     const data = new FormData();
      data.append("startDate", this.state.startDate/*.toISOString()*/);
      data.append("endDate", this.state.endDate/*.toISOString()*/);
      data.append("tokenName", this.state.tokenName);
      // data.append("tokenType", !isEmpty(this.state.tokenType)?this.state.tokenType.value:"");
      data.append("symbol", this.state.symbol);
      data.append("availableCurrency", this.state.availableCurrency.toString());
      data.append("price", this.state.price);
      data.append("minAmt", this.state.minAmt);
      data.append("discount", this.state.discount);
      // data.append("availableSale", this.state.availableSale);
      data.append("maxSupply", this.state.maxSupply);
      data.append("industry", this.state.industry);
      data.append("website", this.state.website);
      data.append("content", this.state.content);
      data.append("image", this.state.image);
      data.append("whitePaper", this.state.whitePaper);
      data.append("telegramLink", this.state.telegramLink);
      data.append("twitterLink", this.state.twitterLink);
      data.append("linkedinLink", this.state.linkedinLink);
      data.append("redditLink", this.state.redditLink);
      data.append("youtubeLink", this.state.youtubeLink);
      data.append("facebookLink", this.state.facebookLink);
      data.append("instagramLink", this.state.instagramLink);
      data.append("decimals", this.state.decimals);
      data.append("contractAddress", this.state.contractAddress);
      data.append("type", 'add');
      
      let { status, message, error } = await addLaunchpad(data)

      if(status){
        toast(message, {
                position: toast.POSITION.TOP_CENTER
        });
        $('#add-launch-modal').modal('hide');
        this.setState({
                errors: {}
          });
        this.props.getData()
      }else if(!status && error){

          this.setState({
                errors: error
          });
      }else{
        toast(message, {
                position: toast.POSITION.TOP_CENTER
        });
      }

    };


    render() {
        const { errors } = this.state;
        console.log("errors",errors)
        
        return (
            <div>
                <div className="modal fade" id="add-launch-modal" data-reset="true" data-keyboard="false" data-backdrop="static">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Launchpad Details</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onLaunchpadAdd} id="add-lauchpad" >
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenname">Token Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.tokenName}
                                                id="tokenName"
                                                type="text"
                                                error={errors.tokenName}
                                                className={classnames("form-control", {
                                                    invalid: errors.tokenName
                                                })}/>
                                            <span className="text-danger">{errors.tokenName}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symbol">Token Symbol</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.symbol}
                                                error={errors.symbol}
                                                id="symbol"
                                                type="symbol"
                                                className={classnames("form-control", {
                                                    invalid: errors.symbol
                                                })}
                                            />
                                            <span className="text-danger">{errors.symbol}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenName">Contract Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.contractAddress}
                                                id="contractAddress"
                                                type="text"
                                                error={errors.contractAddress}
                                                className={classnames("form-control", {
                                                    invalid: errors.contractAddress
                                                })} />
                                            <span className="text-danger">{errors.contractAddress}</span>

                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenName">Decimals</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.decimals}
                                                id="decimals"
                                                type="text"
                                                error={errors.decimals}
                                                className={classnames("form-control", {
                                                    invalid: errors.decimals
                                                })} />
                                            <span className="text-danger">{errors.decimals}</span>

                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symbol">Available Currency</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Select
                                                placeholder={'Select'}
                                                onChange={this.handleOnChange}
                                                options={this.props.currencyOptions}
                                                isMulti
                                            />
                                            <span className="text-danger">{errors.availableCurrency}</span>
                                        </div>
                                   </div> 
                                 {/*<div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symbol">Available Currency</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.availableCurrency}
                                                error={errors.availableCurrency}
                                                id="availableCurrency"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.availableCurrency
                                                })}
                                            />
                                            <span className="text-danger">{errors.availableCurrency}</span>
                                        </div>
                                 </div>*/}
                                <div className="row mt-2">
                                  <div className="col-md-3">
                                  <label htmlFor="minimum">Token ICON</label>
                                  </div>
                                  <div className="col-md-9">
                                  <input
                                        type="file"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={this.handleChangeefile}
                                        name="image"
                                        id="exampleInputFile"
                                        aria-describedby="fileHelp"
                                      />
                                      <span className="text-danger">{errors.image}</span>
                                    <img src={this.state.imageUrl} style={ { 'width': '106px'} } /> 
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-md-3">
                                  <label htmlFor="minimum">White Paper</label>
                                  </div>
                                  <div className="col-md-9">
                                  <input
                                        type="file"
                                        accept="image/x-pdf,image/doc,image/odt"
                                        onChange={this.handleChangeefile}
                                        name="whitePaper"
                                        id="exampleInputFile"
                                        aria-describedby="fileHelp"
                                      />
                                      <span className="text-danger">{errors.whitePaper}</span>
                                     { !isEmpty(this.state.whitePaperUrl) && 
                                       <a target="_blank" href={this.state.whitePaperUrl}>view</a>
                                     }
                                  </div>
                                </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="price">Token Launch Price (USD)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.price}
                                                error={errors.price}
                                                id="price"
                                                type="price"
                                                className={classnames("form-control", {
                                                    invalid: errors.price
                                                })}
                                            />
                                            <span className="text-danger">{errors.price}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Minimum Purchase Amount</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.minAmt}
                                                id="minAmt"
                                                type="minAmt"
                                                className={classnames("form-control", {
                                                    invalid: errors.minAmt
                                                })}
                                            />
                                            <span className="text-danger">{errors.minAmt}</span>
                                        </div>
                                    </div>
                                      <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Discount</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.discount}
                                                id="discount"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.discount
                                                })}
                                            />
                                            <span className="text-danger">{errors.discount}</span>
                                        </div>
                                    </div>
                                    {/*<div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Token Available to Sale</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.availableSale}
                                                id="availableSale"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.availableSale
                                                })}
                                            />
                                            <span className="text-danger">{errors.availableSale}</span>
                                        </div>
                                    </div>*/}
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Token Max Supply</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maxSupply}
                                                id="maxSupply"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.maxsupply
                                                })}
                                            />
                                            <span className="text-danger">{errors.maxSupply}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Industry</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.industry}
                                                id="industry"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.industry
                                                })}
                                            />
                                            <span className="text-danger">{errors.industry}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Website</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.website}
                                                id="website"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.website
                                                })}
                                            />
                                            <span className="text-danger">{errors.website}</span>
                                        </div>
                                    </div>
                                      <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Start Date</label>
                                        </div>
                                        <div className="col-md-9">
                                         <DatePicker
                                          selected={ this.state.startDate }
                                          onChange={ this.handleChange }
                                          dateFormat="MMMM d, yyyy"
                                          className={classnames("form-control", {
                                                    invalid: errors.startDate
                                                })}
                                          dateFormat="MMMM d, yyyy h:mm aa"
                                          showTimeSelect
                                          />
                                            <span className="text-danger">{errors.startDate}</span>
                                        </div>
                                    </div>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">End Date</label>
                                        </div>
                                        <div className="col-md-9">
                                         <DatePicker
                                          selected={ this.state.endDate }
                                          onChange={ this.handleChange1 }
                                          dateFormat="MMMM d, yyyy"
                                          className={classnames("form-control", {
                                                    invalid: errors.endDate
                                                })}
                                          dateFormat="MMMM d, yyyy h:mm aa"
                                          showTimeSelect
                                          />
                                            <span className="text-danger">{errors.endDate}</span>
                                        </div>
                                    </div>
                                       <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="content">Content</label>
                                        </div>
                                        <div className="col-md-9">
                                        <Editor apiKey='5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5'
                                           initialValue="<p>This is the initial content of the editor</p>"
                                           value={this.state.content} onEditorChange={this.handleEditorChange}
                                           init={{
                                             height: 500,
                                             menubar: false,
                                             plugins: [
                                               'advlist autolink lists link image charmap print preview anchor',
                                               'searchreplace visualblocks code fullscreen',
                                               'insertdatetime media table paste code help wordcount'
                                             ],
                                             toolbar:
                                               'undo redo code | formatselect | bold italic backcolor | \
                                               alignleft aligncenter alignright alignjustify | \
                                               bullist numlist outdent indent | removeformat | help'
                                           }}
                                         />
                                            <span className="text-danger">{errors.content}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Telegram Link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.telegramLink}
                                                id="telegramLink"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.telegramLink
                                                })}
                                            />
                                            <span className="text-danger">{errors.telegramLink}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Twitter Link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.twitterLink}
                                                id="twitterLink"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.twitterLink
                                                })}
                                            />
                                            <span className="text-danger">{errors.twitterLink}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Linkedin Link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.linkedinLink}
                                                id="linkedinLink"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.linkedinLink
                                                })}
                                            />
                                            <span className="text-danger">{errors.linkedinLink}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Reddit Link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.redditLink}
                                                id="redditLink"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.redditLink
                                                })}
                                            />
                                            <span className="text-danger">{errors.redditLink}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Youtube Link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.youtubeLink}
                                                id="youtubeLink"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.youtubeLink
                                                })}
                                            />
                                            <span className="text-danger">{errors.youtubeLink}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">FaceBook Link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.facebookLink}
                                                id="facebookLink"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.facebookLink
                                                })}
                                            />
                                            <span className="text-danger">{errors.facebookLink}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Instagram Link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.instagramLink}
                                                id="instagramLink"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.instagramLink
                                                })}
                                            />
                                            <span className="text-danger">{errors.instagramLink}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-lauchpad"
                                    type="submit"
                                    className="btn btn-primary">
                                    Add Launchpad
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

LaunchpadAddModal.propTypes = {
    addLaunchpad: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addLaunchpad}
)(withRouter(LaunchpadAddModal));
