// import config
import axios from '../config/axios';
import config from "../config";

export const getPairDropdown = async () => {
    try {
        const respData = await axios({
            'url': `/adminapi/getPairDropdown`,
            'method': 'get'
        })
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false
        }
    }
}
export const gettradeHistoryChart = async () => {
    try {
      let respData = await axios({
        method: "get",
        url: `/adminapi/gettradehistory_dash`,
      });
      return {
        status: "success",
        loading: false,
        result: respData.data.result,
        arrData:respData.data.arrData
      };
    } catch (err) {
      return {
        status: "failed",
        loading: false,
      };
    }
  };
  export const getTopTradeHistory = async () => {
    try {
      let respData = await axios({
        method: "get",
        url: `/adminapi/getTopTradeHistory`,
      });
      return {
        status: "success",
        loading: false,
        result: respData.data.result,
      };
    } catch (err) {
      return {
        status: "failed",
        loading: false,
      };
    }
  };