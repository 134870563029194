import React, { Component, Fragment } from "react";
import moment from 'moment'
import ReactDatatable from '@ashvin27/react-datatable';
import { withRouter } from 'react-router-dom';
//import component

import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { toastAlert } from '../../lib/toastAlert'

//impport action
import { getUser, userStatusChange, changeTwofa } from "../../actions/userActions";

class Users extends Component {

    constructor(props) {
        super(props);


        this.columns = [
            {
                key: "createdAt",
                text: "Created date",
                className: "date",
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <p>{moment(record.createdAt).format('MMMM,Do YYYY, hh:mm A')}</p>
                        </Fragment>
                    )


                }
            },
            {
                key: "action",
                text: "Name",
                className: "email",
                align: "left",
                sortable: false,
                // selector: row => `${ row.firstName } ${ row.lastName }`
                cell: record => {
                    console.log("recordrecordrecordrecordrecord", record)
                    let Namae = record.firstName + " " + record.lastName
                    return (
                        <Fragment>
                            <p>{Namae}</p>
                        </Fragment>
                    )


                }

            },
            {
                key: "email",
                text: "Email",
                className: "email",
                align: "left",
                sortable: false
            },
            {
                key: "phoneNo",
                text: "Phone",
                className: "email",
                align: "left",
                sortable: false
            },
            {
                key: "address",
                text: "Address",
                className: "address",
                align: "left",
                sortable: false
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: false,
            },

            {
                key: "action",
                text: "Action",
                className: "action",
                width: 200,
                align: "left",
                sortable: false,
                cell: record => {
                    console.log(record, 'google2Fagoogle2Fa')
                    const checkin = record.status;
                    if (checkin == 'verified') {
                        var lockVal = 'fa fa-lock';
                    } else {
                        var lockVal = 'fa fa-unlock';
                    }
                    if ((record.google2Fa.secret != "") && (record.google2Fa.uri != "")) {
                        return (
                            <>
                                <Fragment>
                                    {/*<button
                                className="btn btn-danger btn-sm mr-1"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>*/}
                                    <button
                                        className="btn btn-primary btn-sm mr-1"
                                        onClick={() => this.changestatus(record)}>
                                        <i className={lockVal}></i>
                                    </button>
                                    <button
                                        className="btn btn-danger btn-sm mr-1"
                                        onClick={() => this.changestatus2fa(record)}>
                                        Disable 2FA
                                    </button>
                                    {/*<button
                               className="btn btn-success btn-sm mr-1"
                                onClick={() => this.changestatus(record)}>
                                Enable
                            </button>*/}
                                </Fragment>
                            </>
                        );

                    } else {
                        if (checkin == "deleted") {
                            return   <Fragment>---</Fragment>
                        } else {
                            return (
                                <>
                                    <Fragment>
                                        <button
                                            className="btn btn-primary btn-sm mr-1"
                                            onClick={() => this.changestatus(record)}>
                                            <i className={(lockVal)}></i>
                                        </button>
                                    </Fragment>
                                </>
                            );
                        }

                    }
                }
            },
            {
                key: "action",
                text: "Assets",
                className: "subject",
                align: "left",
                sortable: false,
                cell: (record) => {
                    if (record.uniqueId != 'undefined') {
                        return (
                            <span>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => this.props.history.push("/assetsDetails/" + record._id)}
                                    style={{ marginRight: "5px" }}
                                >
                                    View
                                </button>
                            </span>
                        );
                    }
                },
            },
            {
                key: "action",
                text: "PassBook",
                className: "subject",
                align: "left",
                sortable: false,
                cell: (record) => {
                    if (record.uniqueId != 'undefined') {
                        return (
                            <span>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => this.props.history.push("/passbookHistory/" + record.uniqueId)}
                                    style={{ marginRight: "5px" }}
                                >
                                    View
                                </button>
                            </span>
                        );
                    }
                },
            },
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Perpetual",
            no_data_text: 'No Contracts found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            loader: false,
            page: 1,
            limit: 10,
            count: 0,
            responsive: true,

        };

        this.getUserList = this.getUserList.bind(this);
        this.handlePagination = this.handlePagination.bind(this)
    }

    componentDidMount() {
        const { page, limit } = this.state;
        let reqData = {
            page,
            limit
        }
        this.getUserList(reqData)
    };
    async changestatus(data) {
        console.log(data, 'datattatatat')
        try {
            this.setState({ 'loader': true })
            const { status, loading, result, message } = await userStatusChange(data);
            this.setState({ 'loader': false })
            if (status == 'success') {
                toastAlert('success', message, 'statuschange')
                this.getUserList()
            }
        } catch (err) { }
    }
    async changestatus2fa(data) {
        try {
            this.setState({ 'loader': true })
            const { status, loading, result, message } = await changeTwofa(data);
            this.setState({ 'loader': false })
            console.log(message, 'resultttttttttttt')
            if (status == 'success') {
                toastAlert('success', message, '2fachange')
                this.getUserList()
            }
        } catch (err) { }
    }

    async getUserList(reqData) {

        try {
            this.setState({ 'loader': true })
            const { status, loading, result } = await getUser(reqData);
            this.setState({ 'loader': false })
            if (status == 'success') {
                this.setState({ "count": result.count, 'records': result.data })
            }
        } catch (err) { }
    }
    handlePagination(index) {
        let reqData = {
            page: index.page_number,
            limit: index.page_size,
            search: index.filter_value
        }
        this.getUserList(reqData);
        this.setState({ page: index.page_number, limit: index.page_size, search: index.filter_value })
    }



    render() {
        const { count, loader } = this.state
        return (
            <>

                <UserAddModal />
                <UserUpdateModal record={this.state.currentRecord} />
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <h3 className="mt-2 text-secondary">Users Management</h3>
                        <ReactDatatable
                            responsive={this.state.responsive}
                            config={this.config}
                            records={this.state.records}
                            total_record={count}
                            dynamic={true}
                            columns={this.columns}
                            loading={loader}
                            onChange={this.handlePagination}
                        />
                    </div>
                </div>

            </>



        );
    }

}



export default withRouter(Users);
