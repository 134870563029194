//import config
import axios from "../config/axios";

let announcementSource;

export const anouncementAdd = async (data) => {
    try {
        const respData = await axios({
            url: `/adminapi/anouncement`,
            method: "put",
            data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const getAnnouncementHistory = async (data = {}) => {
    try {

        if (announcementSource) announcementSource.cancel();
        announcementSource = axios.CancelToken.source();

        let respData = await axios({
            method: "post",
            url: `/adminapi/anouncement`,
            data,
            cancelToken: announcementSource.token,
        });
        return {
            status: true,
            totalCount: respData.data.totalCount,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: false,
        };
    }
};