
import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from '@ashvin27/react-datatable';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Scrollbars } from 'react-custom-scrollbars-2';

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import FaqAddModal from "../partials/FaqAddModal";
import FaqUpdateModal from "../partials/FaqUpdateModal";

// import action
import { getFaqCategory, faqList } from '../../actions/faqActions'
import { getNotification, clearAllNotification } from '../../actions/admin'
import { Link } from "react-router-dom";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


class Notification extends Component {
    constructor(props) {
        super(props);

        this.state = {

            loader: false,
            categoryOption: [],
            count: 0,
            records: [],
            param: "showAll"

        };
    }

    componentDidMount() {
        const { param } = this.state;
        let reqData = {
            param
        }
        this.fetchNotification(reqData)
    };


    async fetchNotification(reqData) {
        this.setState({ 'loader': true })
        try {
            const { status, loading, result, count } = await getNotification(reqData);
            this.setState({ 'loader': loading });
            if (status == 'success') {
                this.setState({ "count": count, 'records': result })
            }
        } catch (err) { }
    }

    async clearAll() {
        try {
            const { param } = this.state;

            let reqData = {
                param
            }
            const { data, status, count } = await clearAllNotification();
            if (status == "success") {
                this.fetchNotification(reqData)
            }
        } catch (err) {

        }

    }


    render() {
        const { loader, count, records, } = this.state;
        return (
            <div>
                <li className="nav-item dropdown">
                    <a class="nav-link notify_icon" type="button" id="notifydropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-bell"></i><span className="notify_count">{count}</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right settings_dropmenu notification-dropdown" aria-labelledby="notifydropdownMenuButton">

                        {
                            records && records.length > 0 ?
                                <Scrollbars style={{ width: "100%", height: 375 }}>
                                    {
                                        records && records.map((data, i) => {
                                            return (
                                                <a class="dropdown-item" >{i + 1}. {""} {ReactHtmlParser(data.description)}</a>

                                            )

                                        })
                                    }
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Link to={"/notification"}>
                                            <button className="btn btn-primary">View All</button>
                                        </Link>
                                        <button  className="btn btn-primary ml-2" onClick={() => this.clearAll()} >Clear All</button>
                                    </div>
                                    {/* <a class="dropdown-item" href="#"> 5 new members joined today</a>
                                    <a class="dropdown-item" href="#">2 KYC Verification Pending</a>
                                    <a class="dropdown-item" href="#"> 5 new members joined today</a>
                                    <a class="dropdown-item" href="#">2 KYC Verification Pending</a>
                                    <a class="dropdown-item" href="#"> 5 new members joined today</a>
                                    <a class="dropdown-item" href="#">2 KYC Verification Pending</a>
                                    <a class="dropdown-item" href="#"> 5 new members joined today</a>
                                    <a class="dropdown-item" href="#">2 KYC Verification Pending</a>
                                    <a class="dropdown-item" href="#"> 5 new members joined today</a>
                                    <a class="dropdown-item" href="#">2 KYC Verification Pending</a>      */}
                                </Scrollbars>
                                :
                                <Scrollbars style={{ width: "100%", height: 70 }}>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Link to={"/notification"}>
                                            <button className="btn btn-primary">View All</button>
                                        </Link>
                                    </div>
                                </Scrollbars>

                        }

                    </div>
                </li>
                {/* <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />

                    <div id="page-content-wrapper">
                        <div className="container-fluid">

                            <h3 className="mt-2 text-secondary">Notification History</h3>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                dynamic={true}
                                total_record={count}
                                loading={loader}
                                onChange={this.handlePagination}
                            />
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }

}

export default Notification;
