import React from "react";
import classnames from "classnames";
import { Modal, Form } from "react-bootstrap";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import fileObjectUrl from "../../lib/fileObjectUrl";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
// import action
import { addAdmin } from "../../actions/currency";
import "react-toastify/dist/ReactToastify.css";

const initialFormValue = {
    role: "",
    name: "",
    email: "",
    password: "",
    ethprivatekey: "",
};

class AdminSideAddModal extends React.Component {
    constructor() {
        super();
        this.state = {
            formValue: initialFormValue,
            // "Dashborad" : false,
            User: false,
            UserBalance: false,
            Currency: false,
            PriceConversation: false,
            SpotPair: false,
            SideSetting: false,
            sitemap: false,
            FaqCategory: false,
            Faq: false,
            SupportCategory: false,
            Support: false,
            EmailTemplate: false,
            CmsPage: false,
            Kyc: false,
            WithdrawList: false,
            DepositList: false,
            ContactUs: false,
            Newsletter: false,
            Announcement: false,
            Launchpad: false,
            LaunchpadCms: false,
            Language: false,
            SpotOrderHistory: false,
            SpotTradeHistory: false,
            TradingBot: false,
            BlogCategory: false,
            BlogArticle: false,
            Staking: false,
            p2ptradehistory: false,
            p2pchathistory: false,
            p2pdisputelist: false,
            p2pcommissionhistory: false,
            stackingorder: false,
            stackingsettlement: false,
            airdrop: false,
            refferalcommisonhistory: false,
            feeandrefferal: false,
            p2ppair: false,
            airdropHistory:false,
            deleteuserList:false,
            errors: {},
        };
    }

    handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...this.state.formValue, ...{ [name]: value } };
        this.setState({ formValue: formData });
    };

    handleClose = () => {
        const { onHide } = this.props;
        onHide();
        this.setState({ formValue: initialFormValue, errors: {} });
    };

    handleFile = (e) => {
        e.preventDefault();
        const { name, files } = e.target;
        let formData = { ...this.state.formValue, ...{ [name]: files[0] } };
        this.setState({ formValue: formData });
    };
    handleSubmit = async (e) => {
        e.preventDefault();
        const { fetchData, closeModal } = this.props;

        const {
            formValue,
            User,
            UserBalance,
            Currency,
            PriceConversation,
            SpotPair,
            sitemap,
            SideSetting,
            FaqCategory,
            Faq,
            SupportCategory,
            Support,
            EmailTemplate,
            CmsPage,
            Kyc,
            WithdrawList,
            DepositList,
            ContactUs,
            Newsletter,
            Announcement,
            Launchpad,
            LaunchpadCms,
            Language,
            SpotOrderHistory,
            SpotTradeHistory,
            TradingBot,
            BlogCategory,
            BlogArticle,
            Staking,
            p2ptradehistory,
            p2pchathistory,
            p2pdisputelist,
            p2pcommissionhistory,
            stackingorder,
            stackingsettlement,
            airdrop,
            refferalcommisonhistory,
            feeandrefferal,
            p2ppair,
            airdropHistory,
            deleteuserList
        } = this.state;
        let reqData = {
            ...formValue,
            User,
            UserBalance,
            Currency,
            PriceConversation,
            SpotPair,
            sitemap,
            SideSetting,
            FaqCategory,
            Faq,
            SupportCategory,
            Support,
            EmailTemplate,
            CmsPage,
            Kyc,
            WithdrawList,
            DepositList,
            ContactUs,
            Newsletter,
            Announcement,
            Launchpad,
            LaunchpadCms,
            Language,
            SpotOrderHistory,
            SpotTradeHistory,
            TradingBot,
            BlogCategory,
            BlogArticle,
            Staking,
            p2ptradehistory,
            p2pchathistory,
            p2pdisputelist,
            p2pcommissionhistory,
            stackingorder,
            stackingsettlement,
            airdrop,
            refferalcommisonhistory,
            feeandrefferal,
            p2ppair,
            airdropHistory,
            deleteuserList
        };

        // const formData = new FormData();
        // formData.append("type", type);
        // formData.append("currencyName", currencyName);
        // formData.append("currencySymbol", currencySymbol);
        // formData.append("contractAddress", contractAddress);
        // formData.append("minABI", minABI);
        // formData.append("decimals", decimals);
        // formData.append("withdrawFee", withdrawFee);
        // formData.append("minimumWithdraw", minimumWithdraw);
        // formData.append("bankName", bankName);
        // formData.append("accountNo", accountNo);
        // formData.append("holderName", holderName);
        // formData.append("bankcode", bankcode);
        // formData.append("country", country);
        // formData.append("currencyImage", currencyImage);
        this.setState({ loader: true });
        try {
            const { status, loading, message, error } = await addAdmin(reqData);
            this.setState({ loader: loading });
            if (status == "success") {
                this.handleClose();
                fetchData();
                toastAlert("success", message, "AdminSideAddModal");
            } else if (status == "failed") {
                if (error) {
                    this.setState({ errors: error });
                } else {
                    toastAlert("error", message, "AdminSideAddModal");
                }
            }
        } catch (err) {}
    };

    render() {
        const { role, name, companyName, email, password, ethprivatekey } =
            this.state.formValue;
        const { errors, loader } = this.state;

        const { isShow } = this.props;

        return (
            <div>
                <Modal
                    show={isShow}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    size="lg"
                    centered
                    scrollable={true}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <h4 className="modal-title">Add Admin</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <form
                            noValidate
                            onSubmit={this.handleSubmit}
                            id="add-template"
                        >
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">
                                        Select Admin type
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <Form.Control
                                        as="select"
                                        custom
                                        name={"role"}
                                        value={role}
                                        onChange={this.handleChange}
                                    >
                                        <option value={""}>
                                            {"Select Admin Type"}
                                        </option>
                                        {/* <option value={"Admin"}>
                                            {"Admin"}
                                        </option> */}
                                        <option value={"subadmin"}>
                                            {"subadmin"}
                                        </option>
                                    </Form.Control>
                                    <span className="text-danger">
                                        {errors.serviceId}
                                    </span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Name</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChange}
                                        value={name}
                                        name="name"
                                        type="text"
                                        error={errors.name}
                                        className={classnames("form-control", {
                                            invalid: errors.name,
                                        })}
                                    />
                                    <span className="text-danger">
                                        {errors.name}
                                    </span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Email</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChange}
                                        value={email}
                                        name="email"
                                        type="text"
                                        error={errors.email}
                                        className={classnames("form-control", {
                                            invalid: errors.email,
                                        })}
                                    />
                                    <span className="text-danger">
                                        {errors.email}
                                    </span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">password</label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        onChange={this.handleChange}
                                        value={password}
                                        name="password"
                                        type="password"
                                        error={errors.password}
                                        className={classnames("form-control", {
                                            invalid: errors.password,
                                        })}
                                    />
                                    <span className="text-danger">
                                        {errors.password}
                                    </span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">User</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.User}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="User"
                                        onChange={(checked) => {
                                            this.setState({ User: checked });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Deleted users list</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.deleteuserList}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="deleteuserList"
                                        onChange={(checked) => {
                                            this.setState({ deleteuserList: checked });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">UserBalance</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.UserBalance}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="UserBalance"
                                        onChange={(checked) => {
                                            this.setState({
                                                UserBalance: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Kyc</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.Kyc}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="Kyc"
                                        onChange={(checked) => {
                                            this.setState({ Kyc: checked });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Currency</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.Currency}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="Currency"
                                        onChange={(checked) => {
                                            this.setState({
                                                Currency: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Spot Pair</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.SpotPair}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="SpotPair"
                                        onChange={(checked) => {
                                            this.setState({
                                                SpotPair: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">
                                        Spot Order History
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.SpotOrderHistory}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="SpotOrderHistory"
                                        onChange={(checked) => {
                                            this.setState({
                                                SpotOrderHistory: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">
                                        Spot Trade History
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.SpotTradeHistory}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="SpotTradeHistory"
                                        onChange={(checked) => {
                                            this.setState({
                                                SpotTradeHistory: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">P2P Pair</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.p2ppair}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="p2ppair"
                                        onChange={(checked) => {
                                            this.setState({ p2ppair: checked });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">
                                        P2P Trade History
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.p2ptradehistory}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="p2ptradehistory"
                                        onChange={(checked) => {
                                            this.setState({
                                                p2ptradehistory: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">
                                        P2P Dispute Trade
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.p2pdisputelist}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="p2pdisputelist"
                                        onChange={(checked) => {
                                            this.setState({
                                                p2pdisputelist: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">
                                        P2P Commission History
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={
                                            this.state.p2pcommissionhistory
                                        }
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="p2pcommissionhistory"
                                        onChange={(checked) => {
                                            this.setState({
                                                p2pcommissionhistory: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Staking</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.Staking}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="Staking"
                                        onChange={(checked) => {
                                            this.setState({ Staking: checked });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Staking Order</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.stackingorder}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="stackingorder"
                                        onChange={(checked) => {
                                            this.setState({
                                                stackingorder: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">
                                        Staking Settlement
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.stackingsettlement}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="stackingsettlement"
                                        onChange={(checked) => {
                                            this.setState({
                                                stackingsettlement: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Deposit List</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.DepositList}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="DepositList"
                                        onChange={(checked) => {
                                            this.setState({
                                                DepositList: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Withdraw List</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.WithdrawList}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="WithdrawList"
                                        onChange={(checked) => {
                                            this.setState({
                                                WithdrawList: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">
                                        Support Category
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.SupportCategory}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="SupportCategory"
                                        onChange={(checked) => {
                                            this.setState({
                                                SupportCategory: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Support</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.Support}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="Support"
                                        onChange={(checked) => {
                                            this.setState({ Support: checked });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Airdrop</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.airdrop}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="airdrop"
                                        onChange={(checked) => {
                                            this.setState({ airdrop: checked });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Airdrop History</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.airdropHistory}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="airdropHistory"
                                        onChange={(checked) => {
                                            this.setState({ airdropHistory: checked });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">
                                        Fee And Refferral
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.feeandrefferal}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="feeandrefferal"
                                        onChange={(checked) => {
                                            this.setState({
                                                feeandrefferal: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">
                                        Refferral Commission History
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={
                                            this.state.refferalcommisonhistory
                                        }
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="refferalcommisonhistory"
                                        onChange={(checked) => {
                                            this.setState({
                                                refferalcommisonhistory:
                                                    checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Launchpad</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.Launchpad}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="Launchpad"
                                        onChange={(checked) => {
                                            this.setState({
                                                Launchpad: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">LaunchpadCms</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.LaunchpadCms}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="LaunchpadCms"
                                        onChange={(checked) => {
                                            this.setState({
                                                LaunchpadCms: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Blog Category</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.BlogCategory}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="BlogCategory"
                                        onChange={(checked) => {
                                            this.setState({
                                                BlogCategory: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Blog Article</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.BlogArticle}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="BlogArticle"
                                        onChange={(checked) => {
                                            this.setState({
                                                BlogArticle: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">ContactUs</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.ContactUs}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="ContactUs"
                                        onChange={(checked) => {
                                            this.setState({
                                                ContactUs: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Newsletter</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.Newsletter}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="Newsletter"
                                        onChange={(checked) => {
                                            this.setState({
                                                Newsletter: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Announcement</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.Announcement}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="Announcement"
                                        onChange={(checked) => {
                                            this.setState({
                                                Announcement: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">
                                        PriceConversation
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.PriceConversation}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="PriceConversation"
                                        onChange={(checked) => {
                                            this.setState({
                                                PriceConversation: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Faq Category</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.FaqCategory}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="FaqCategory"
                                        onChange={(checked) => {
                                            this.setState({
                                                FaqCategory: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Faq</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.Faq}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="Faq"
                                        onChange={(checked) => {
                                            this.setState({ Faq: checked });
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Email Template</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.EmailTemplate}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="EmailTemplate"
                                        onChange={(checked) => {
                                            this.setState({
                                                EmailTemplate: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Cms Page</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.CmsPage}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="CmsPage"
                                        onChange={(checked) => {
                                            this.setState({ CmsPage: checked });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Site Setting</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.SideSetting}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="SideSetting"
                                        onChange={(checked) => {
                                            this.setState({
                                                SideSetting: checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Site Map</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.sitemap}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="sitemap"
                                        onChange={(checked) => {
                                            this.setState({ sitemap: checked });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Trading Bot</label>
                                </div>
                                <div className="col-md-9">
                                    <BootstrapSwitchButton
                                        checked={this.state.TradingBot}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        name="TradingBot"
                                        onChange={(checked) => {
                                            this.setState({ TradingBot: checked });
                                        }}
                                    />
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={this.handleClose}
                        >
                            Close
                        </button>
                        <button
                            onClick={this.handleSubmit}
                            className="btn btn-primary"
                        >
                            {loader && <i class="fas fa-spinner fa-spin"></i>}
                            Add Admin
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default AdminSideAddModal;