import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import jsPDF from "jspdf";
import moment from "moment";
import { Form } from "react-bootstrap";

// import actions
import { spotTradeHistory } from "../../actions/reportActions";

// import library
import { capitalize } from "../../lib/capitalize";

import keys from "../../config";

class tradehistory extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "createdAt",
        text: "Date",
        className: "Date",
        align: "left",
        sortable: false,
        cell: (record) => {
          return moment(record.createdAt).format("DD-MM-YYYY HH:mm");
        },
      },
      {
        key: "_id",
        text: "Trade ID",
        className: "pairName",
        align: "left",
        sortable: false,
      },
      {
        key: "firstCurrency",
        text: "Base Currency",
        className: "pairName",
        align: "left",
        sortable: false,
      },
      {
        key: "secondCurrency",
        text: "Quote Currency",
        className: "name",
        align: "left",
        sortable: false,
      },
      {
        key: "buyorsell",
        text: "Side",
        className: "Side",
        align: "left",
        sortable: false,
        cell: (record) => {
          return capitalize(record.buyorsell);
        },
      },
      {
        key: "buyerInfo.email",
        text: "Buyer Email",
        className: "Side",
        align: "left",
        sortable: false,
        cell: (record) => {
          let email = "-";
          if (record.buyerInfo && record.buyerInfo.email)
            email = `${record.buyerInfo.email} (${record.secondCurrency} -> ${record.firstCurrency})`;
          else if (record.liquidityType == "binance")
            email = `${keys.BINANCE.Email} (${record.secondCurrency} -> ${record.firstCurrency})`;
          else if (record.botstatus == "wazirx")
            email = `${keys.WAZIRX.Email} (${record.secondCurrency} -> ${record.firstCurrency})`;

          return email;
        },
      },
      {
        key: "buyerInfo.phoneNo",
        text: "Buyer PhoneNo",
        className: "Side",
        align: "left",
        sortable: false,
        cell: (record) => {
          let phoneNo = "-";
          if (record.buyerInfo && record.buyerInfo.phoneNo)
            phoneNo = `${record.buyerInfo.phoneNo} (${record.secondCurrency} -> ${record.firstCurrency})`;
          else if (!record.buyerInfo && record.liquidityType == "binance")
            phoneNo = `${keys.BINANCE.PhoneNo} (${record.secondCurrency} -> ${record.firstCurrency})`;
          else if (!record.buyerInfo && record.botstatus == "wazirx")
            phoneNo = `${keys.WAZIRX.PhoneNo} (${record.secondCurrency} -> ${record.firstCurrency})`;

          return phoneNo;
        },
      },
      {
        key: "sellerInfo.email",
        text: "Seller Email",
        className: "Side",
        align: "left",
        sortable: false,
        cell: (record) => {
          let email = "-";
          if (record.sellerInfo && record.sellerInfo.email)
            email = `${record.sellerInfo.email} (${record.firstCurrency} -> ${record.secondCurrency})`;
          else if (!record.sellerInfo && record.liquidityType == "binance")
            email = `${keys.BINANCE.Email} (${record.firstCurrency} -> ${record.secondCurrency})`;
          else if (!record.sellerInfo && record.botstatus == "wazirx")
            email = `${keys.WAZIRX.Email} (${record.firstCurrency} -> ${record.secondCurrency})`;

          return email;
        },
      },
      {
        key: "sellerInfo.phoneNo",
        text: "Seller PhoneNo",
        className: "Side",
        align: "left",
        sortable: false,
        cell: (record) => {
          let phoneNo = "-";
          if (record.sellerInfo && record.sellerInfo.phoneNo)
            phoneNo = `${record.sellerInfo.phoneNo} (${record.secondCurrency})`;
          else if (!record.sellerInfo && record.liquidityType == "binance")
            phoneNo = `${keys.BINANCE.PhoneNo} (${record.secondCurrency})`;
          else if (!record.sellerInfo && record.botstatus == "wazirx")
            phoneNo = `${keys.WAZIRX.PhoneNo} (${record.secondCurrency})`;

          return phoneNo;
        },
      },
      {
        key: "price",
        text: "Price",
        className: "Price",
        align: "left",
        sortable: false,
        cell: (record) => {
          return record.price ? (
            <>
              {parseFloat(parseFloat(record.price).toFixed(8))}{" "}
              {record.secondCurrency}
            </>
          ) : (
            <>0 {record.secondCurrency}</>
          );
        },
      },
      {
        key: "filledQuantity",
        text: "Executed",
        className: "Excuted",
        align: "left",
        sortable: false,
        cell: (record) => {
          return record.filledQuantity ? (
            <>
              {parseFloat(parseFloat(record.filledQuantity).toFixed(8))}{" "}
              {record.firstCurrency}
            </>
          ) : (
            <>0 {record.firstCurrency}</>
          );
        },
      },
      {
        key: "orderValue",
        text: "Total",
        className: "Total",
        align: "left",
        sortable: false,
        cell: (record) => {
          return record.orderValue ? (
            <>
              {parseFloat(parseFloat(record.orderValue).toFixed(8))}{" "}
              {record.secondCurrency}
            </>
          ) : (
            <>0 {record.secondCurrency}</>
          );
        },
      },
      {
        key: "Fees",
        text: "Fee",
        className: "Fees",
        align: "left",
        sortable: false,
        cell: (record) => {
          let feeType =
            record.OwnToken == "MUDRA"
              ? "MUDRA"
              : record.buyorsell == "buy"
              ? record.firstCurrency
              : record.secondCurrency;
          return record.Fees ? (
            <>
              {parseFloat(record.Fees).toFixed(8)} {feeType}
            </>
          ) : (
            <>0 {feeType}</>
          );
        },
      },
    ];

    this.state = {
      records: [],
      totalCount: 0,
      offset: 0,
      pageSize: 10,
      sortOrder: {
        column: "createdAt",
        order: "desc",
      },
      loading: true,
      exportType: "current",
      extraButtons: [
        {
          className: "btn btn-primary buttons-pdf",
          title: "Export PDF",
          children: [
            <span>
              <i
                className="glyphicon glyphicon-print fa fa-file-pdf-o"
                aria-hidden="true"
              ></i>
            </span>,
          ],
          onClick: (event) => {
            this.exportPDF();
          },
        },
        {
          className: "btn btn-primary buttons-csv",
          title: "Export CSV",
          children: [
            <span>
              <i
                className="glyphicon glyphicon-print fa fa-file-excel-o"
                aria-hidden="true"
              ></i>
            </span>,
          ],
          onClick: (event) => {
            this.exportCSV("csv");
          },
        },
        {
          className: "btn btn-primary buttons-xls",
          title: "Export XLS",
          children: [
            <span>
              <i
                className="glyphicon glyphicon-print fa fa-file-text-o"
                aria-hidden="true"
              ></i>
            </span>,
          ],
          onClick: (event) => {
            this.exportCSV("xls");
          },
        },
      ],
    };

    this.config = {
      page_size: this.state.pageSize,
      sort: this.state.sortOrder,
      length_menu: [10, 20, 50],
      no_data_text: "No Records found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      key_column: "createdAt",
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
      button: {
        extra: true,
      },
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  async getData(filter = "") {
    try {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let data = {
        timezone,
        sortOrder: this.state.sortOrder,
        filter,
        limit: this.state.pageSize,
        offset: this.state.offset,
      };
      const { status, result, totalCount } = await spotTradeHistory(data);
      if (status == "success") {
        this.setState({ loading: false });
        this.setState({ records: [], totalCount: 0 }, function () {
          this.setState({ totalCount, records: result });
        });
      }
    } catch (err) {}
  }

  search = (data) => {
    let filter = data.filter_value;
    let pageSize = data.page_size;
    let offset = (data.page_number - 1) * pageSize;
    let sortOrder = data.sort_order ? data.sort_order : this.state.sortOrder;
    this.setState(
      { records: [], offset, pageSize, sortOrder, filter, loading: true },
      function () {
        this.getData(filter);
      }
    );
  };

  exportCSV = async (fileType) => {
    let { sortOrder, filter, pageSize, offset, totalCount, exportType } =
      this.state;
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let data = {
      timezone,
      sortOrder,
      filter,
      limit: exportType == "current" ? pageSize : totalCount,
      offset: exportType == "current" ? offset : 0,
      download: 1,
    };

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[1].children = [
        <span>
          <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
        </span>,
      ];
      return { extraButtons };
    });

    let { status, result } = await spotTradeHistory(data);

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[1].children = [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-file-excel-o"
            aria-hidden="true"
          ></i>
        </span>,
      ];
      return { extraButtons };
    });

    let records = status ? result : [];
    let separator = ";";
    let headers = [
      "Date",
      "Trade ID",
      "Base Currency",
      "Quote Currency",
      "Side",
      "Buyer Email",
      "Buyer PhoneNo",
      "Seller Email",
      "Seller PhoneNo",
      "Price",
      "Executed",
      "Total",
      "Fee",
    ];

    let rows = [];
    records.length > 0 &&
      records.map((elt) =>
        rows.push({
          Date: moment(elt.createdAt).format("DD-MM-YYYY HH:mm"),
          "Trade ID": elt._id,
          "Base Currency": elt.firstCurrency,
          "Quote Currency": elt.secondCurrency,
          Side: capitalize(elt.buyorsell),
          "Buyer Email":
            elt.buyerInfo && elt.buyerInfo.email
              ? `${elt.buyerInfo.email} (${elt.secondCurrency} -> ${elt.firstCurrency})`
              : !elt.buyerInfo && elt.liquidityType == "binance"
              ? `${keys.BINANCE.Email} (${elt.secondCurrency} -> ${elt.firstCurrency})`
              : !elt.buyerInfo && elt.botstatus == "wazirx"
              ? `${keys.WAZIRX.Email} (${elt.secondCurrency} -> ${elt.firstCurrency})`
              : "-",
          "Buyer PhoneNo":
            elt.buyerInfo && elt.buyerInfo.phoneNo
              ? `${elt.buyerInfo.phoneNo}(${elt.secondCurrency} -> ${elt.firstCurrency})`
              : !elt.buyerInfo && elt.liquidityType == "binance"
              ? `${keys.BINANCE.PhoneNo} (${elt.secondCurrency} -> ${elt.firstCurrency})`
              : !elt.buyerInfo && elt.botstatus == "wazirx"
              ? `${keys.WAZIRX.PhoneNo} (${elt.secondCurrency} -> ${elt.firstCurrency})`
              : "-",
          "Seller Email":
            elt.sellerInfo && elt.sellerInfo.email
              ? `${elt.sellerInfo.email} (${elt.firstCurrency} -> ${elt.secondCurrency})`
              : !elt.sellerInfo && elt.liquidityType == "binance"
              ? `${keys.BINANCE.Email} (${elt.firstCurrency} -> ${elt.secondCurrency})`
              : !elt.sellerInfo && elt.botstatus == "wazirx"
              ? `${keys.WAZIRX.Email} (${elt.firstCurrency} -> ${elt.secondCurrency})`
              : "-",
          "Seller PhoneNo":
            elt.sellerInfo && elt.sellerInfo.phoneNo
              ? `${elt.sellerInfo.phoneNo} (${elt.firstCurrency} -> ${elt.secondCurrency})`
              : !elt.sellerInfo && elt.liquidityType == "binance"
              ? `${keys.BINANCE.PhoneNo} (${elt.firstCurrency} -> ${elt.secondCurrency})`
              : !elt.sellerInfo && elt.botstatus == "wazirx"
              ? `${keys.WAZIRX.PhoneNo} (${elt.firstCurrency} -> ${elt.secondCurrency})`
              : "-",
          Price: elt.price
            ? `${parseFloat(parseFloat(elt.price).toFixed(8))} ${
                elt.secondCurrency
              }`
            : `0 ${elt.secondCurrency}`,
          Executed: elt.filledQuantity
            ? `${parseFloat(parseFloat(elt.filledQuantity).toFixed(8))} ${
                elt.firstCurrency
              }`
            : `0 ${elt.firstCurrency}`,
          Total: elt.orderValue
            ? `${parseFloat(parseFloat(elt.orderValue).toFixed(8))} ${
                elt.secondCurrency
              }`
            : `0 ${elt.secondCurrency}`,
          Fee: elt.Fees
            ? `${parseFloat(elt.Fees).toFixed(8)} ${
                elt.OwnToken == "MUDRA"
                  ? "MUDRA"
                  : elt.buyorsell == "buy"
                  ? elt.firstCurrency
                  : elt.secondCurrency
              }`
            : "-",
        })
      );

    let csvContent = `${headers.join(separator)}\n${rows
      .map((row) =>
        headers
          .map((k) => {
            let cell = row[k] === null || row[k] === undefined ? "" : row[k];

            cell =
              cell instanceof Date
                ? cell.toLocaleString()
                : cell.toString().replace(/"/g, '""');

            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          })
          .join(separator)
      )
      .join("\n")}`;

    let title = "Spot Trade History";
    let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // In case of IE 10+
      navigator.msSaveBlob(blob, title);
    } else {
      let link = document.createElement("a");
      let type = fileType == "csv" ? title : `${title}.xls`;
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", type);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  async exportPDF() {
    let { sortOrder, filter, pageSize, offset, totalCount, exportType } =
      this.state;
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let data = {
      timezone,
      sortOrder,
      filter,
      limit: exportType == "current" ? pageSize : totalCount,
      offset: exportType == "current" ? offset : 0,
      download: 1,
    };

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[0].children = [
        <span>
          <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
        </span>,
      ];
      return { extraButtons };
    });

    let { status, result } = await spotTradeHistory(data);

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[0].children = [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-file-pdf-o"
            aria-hidden="true"
          ></i>
        </span>,
      ];
      return { extraButtons };
    });

    let records = status ? result : [];

    let unit = "pt";
    let size = "A4"; // Use A1, A2, A3 or A4
    let orientation = "landscape"; // portrait or landscape

    let marginLeft = 40;
    let doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);

    let title = "Spot Trade History";
    let headers = [
      [
        "Date",
        "Trade ID",
        "Base Currency",
        "Quote Currency",
        "Side",
        "Buyer Email",
        "Buyer PhoneNo",
        "Seller Email",
        "Seller PhoneNo",
        "Price",
        "Executed",
        "Total",
        "Fee",
      ],
    ];

    let downloadData =
      records.length > 0 &&
      records.map((elt) => [
        moment(elt.createdAt).format("DD-MM-YYYY HH:mm"),
        elt._id,
        elt.firstCurrency,
        elt.secondCurrency,
        capitalize(elt.buyorsell),
        elt.buyerInfo && elt.buyerInfo.email
          ? `${elt.buyerInfo.email} ((${elt.secondCurrency} -> ${elt.firstCurrency})`
          : !elt.buyerInfo && elt.liquidityType == "binance"
          ? `${keys.BINANCE.Email} (${elt.secondCurrency} -> ${elt.firstCurrency})`
          : !elt.buyerInfo && elt.botstatus == "wazirx"
          ? `${keys.WAZIRX.Email} (${elt.secondCurrency} -> ${elt.firstCurrency})`
          : "-",
        elt.buyerInfo && elt.buyerInfo.phoneNo
          ? `${elt.buyerInfo.phoneNo} (${elt.secondCurrency} -> ${elt.firstCurrency})`
          : !elt.buyerInfo && elt.liquidityType == "binance"
          ? `${keys.BINANCE.PhoneNo} (${elt.secondCurrency} -> ${elt.firstCurrency})`
          : !elt.buyerInfo && elt.botstatus == "wazirx"
          ? `${keys.WAZIRX.PhoneNo} (${elt.secondCurrency} -> ${elt.firstCurrency})`
          : "-",
        elt.sellerInfo && elt.sellerInfo.email
          ? `${elt.sellerInfo.email} (${elt.firstCurrency} -> ${elt.secondCurrency})`
          : !elt.sellerInfo && elt.liquidityType == "binance"
          ? `${keys.BINANCE.Email} (${elt.firstCurrency} -> ${elt.secondCurrency})`
          : !elt.sellerInfo && elt.botstatus == "wazirx"
          ? `${keys.WAZIRX.Email} (${elt.firstCurrency} -> ${elt.secondCurrency})`
          : "-",
        elt.sellerInfo && elt.sellerInfo.phoneNo
          ? `${elt.sellerInfo.phoneNo} (${elt.firstCurrency} -> ${elt.secondCurrency})`
          : !elt.sellerInfo && elt.liquidityType == "binance"
          ? `${keys.BINANCE.PhoneNo} (${elt.firstCurrency} -> ${elt.secondCurrency})`
          : !elt.sellerInfo && elt.botstatus == "wazirx"
          ? `${keys.WAZIRX.PhoneNo} (${elt.firstCurrency} -> ${elt.secondCurrency})`
          : "-",
        elt.price
          ? `${parseFloat(parseFloat(elt.price).toFixed(8))} ${
              elt.secondCurrency
            }`
          : `0 ${elt.secondCurrency}`,
        elt.filledQuantity
          ? `${parseFloat(parseFloat(elt.filledQuantity).toFixed(8))} ${
              elt.firstCurrency
            }`
          : `0 ${elt.firstCurrency}`,
        elt.orderValue
          ? `${parseFloat(parseFloat(elt.orderValue).toFixed(8))} ${
              elt.secondCurrency
            }`
          : `0 ${elt.secondCurrency}`,
        elt.Fees
          ? `${parseFloat(elt.Fees).toFixed(8)} ${
              elt.OwnToken == "MUDRA"
                ? "MUDRA"
                : elt.buyorsell == "buy"
                ? elt.firstCurrency
                : elt.secondCurrency
            }`
          : "-",
      ]);

    let content = {
      startY: 50,
      head: headers,
      body: downloadData,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Spot Trade History.pdf");
  }

  render() {
    const { records, count, loading } = this.state;
    return (
      <>
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div className="float-xl-right">
              Export
              {["current", "all"].map((v) => (
                <Form.Check
                  inline
                  type="radio"
                  name="exportType"
                  value={v}
                  key={v}
                  onChange={(e) =>
                    this.setState({ exportType: e.target.value })
                  }
                  defaultChecked={v == this.state.exportType}
                  label={capitalize(v)}
                />
              ))}
            </div>
            <h3 className="mt-2 text-secondary">Spot Trade History</h3>
            <ReactDatatable
              config={this.config}
              records={this.state.records}
              columns={this.columns}
              dynamic={true}
              total_record={this.state.totalCount}
              loading={this.state.loading}
              onChange={this.search}
              extraButtons={this.state.extraButtons}
            />
          </div>
        </div>
      </>
    );
  }
}

export default tradehistory;
