// import axios from "axios";
// import {
//     GET_ERRORS,
//     CURRENCY_ADD,
//     CURRENCY_UPDATE,
// } from "./types";
// import keys from "./config";
// const url = keys.baseUrl;



// // export const addcategory = (categoryData) => dispatch => {
// //     axios
// //         .post(url+"api/category-add", categoryData)
// //         .then(res =>
// //             dispatch({
// //                 type: CURRENCY_ADD,
// //                 payload: res,
// //             })
// //         ).catch(err => 
// //         dispatch({
// //             type: GET_ERRORS,
// //             payload: err.response.data
// //         })
// //     );
// // };


// // export const categoryupdate = (newcategory) => dispatch => {
// //     console.log("Inside the axiosss")
// //     axios
// //         .post(url+"api/category_update", newcategory)
// //         .then(res =>
// //             dispatch({
// //                 type: CURRENCY_UPDATE,
// //                 payload: res,
// //             })
// //         ).catch(err =>
// //         dispatch({
// //             type: GET_ERRORS,
// //             payload: err.response.data
// //         })
// //     );
// // };

// // export const addsubcategory = (newcategory) => dispatch => {
// //     console.log("Inside the axiosss")
// //     axios
// //         .post(url+"api/sub_category_add", newcategory)
// //         .then(res =>
// //             dispatch({
// //                 type: CURRENCY_UPDATE,
// //                 payload: res,
// //             })
// //         ).catch(err =>
// //         dispatch({
// //             type: GET_ERRORS,
// //             payload: err.response.data
// //         })
// //     );
// // };

// // export const updatesubcategory = (newcategory) => dispatch => {
// //     console.log("Inside the axiosss")
// //     axios
// //         .post(url+"api/sub_category_update", newcategory)
// //         .then(res =>
// //             dispatch({
// //                 type: CURRENCY_UPDATE,
// //                 payload: res,
// //             })
// //         ).catch(err =>
// //         dispatch({
// //             type: GET_ERRORS,
// //             payload: err.response.data
// //         })
// //     );
// // };


// // export const addarticle = (newarticle) => dispatch => {
// //     console.log("Inside the axiosss")
// //     axios
// //         .post(url+"api/article-add", newarticle)
// //         .then(res =>
// //             dispatch({
// //                 type: CURRENCY_UPDATE,
// //                 payload: res,
// //             })
// //         ).catch(err =>
// //         dispatch({
// //             type: GET_ERRORS,
// //             payload: err.response.data
// //         })
// //     );
// // };

// export const updatearticle = (newcategory) => dispatch => {
//     console.log("Inside the axiosss")
//     axios
//         .post(url+"api/article-update", newcategory)
//         .then(res =>
//             dispatch({
//                 type: CURRENCY_UPDATE,
//                 payload: res,
//             })
//         ).catch(err =>
//         dispatch({
//             type: GET_ERRORS,
//             payload: err.response.data
//         })
//     );
// };

