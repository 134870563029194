import axios from "axios";
import {
  GET_ERRORS,
  USER_ADD,
  USER_UPDATE,
  PROFILE_UPDATE,
  PASSWORD_UPDATE,
  SETTINGS_UPDATE,
  REPLY_CONTACT,
  SUPPORT_REPLY,
} from "./types";
import keys from "./config";
const url = keys.baseUrl;
export const addUser = (userData, history) => (dispatch) => {
  axios
    .post(url + "api/user-add", userData)
    .then((res) =>
      dispatch({
        type: USER_ADD,
        payload: res,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const updateUser = (userData) => (dispatch) => {
  axios
    .post(url + "api/user-update", userData)
    .then((res) =>
      dispatch({
        type: USER_UPDATE,
        payload: res,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const updateProfile = (userData) => (dispatch) => {
  console.log(userData, "userData2");
  axios
    .post(url + "adminapi/profileupload", userData)
    .then((res) =>
      dispatch({
        type: PROFILE_UPDATE,
        payload: res,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const getadmindetail = (userData) => (dispatch) => {
  console.log(userData, "userData2");
  axios
    .get(url + "adminapi/getadmindetail")
    .then((res) =>
      dispatch({
        type: PROFILE_UPDATE,
        payload: res,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const onEmailupdateaction = (userData) => (dispatch) => {
  console.log(userData, "userData2");
  axios
    .get(url + "adminapi/onEmailupdate")
    .then((res) =>
      dispatch({
        type: PROFILE_UPDATE,
        payload: res,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
export const updateChangepassword =async (userData)  => {
   
   
  try {
       console.log("userDatauserDatauserData",userData)
      let respData = await axios({
          'method': 'post',
          'url': `/adminApi/changePassword`,
           'data':userData
      });

      return {
          status: true,
          loading: false,
          message: respData.data.messages
      }
  }
  catch (err) {
      return {
          status: false,
          loading: false,
          message: err.response.data.message,
          error: err.response.data.errors
      }
  }
};

export const newsletteremail = (userData) => dispatch => {
    axios.post(url + "adminApi/sendnewsletter", userData).then(res => {
        dispatch({
            type: PROFILE_UPDATE,
            payload: res,
        })
    }).catch(err => {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data.errors
        })
    });
};

export const updateSettings = (userData) => (dispatch) => {
  axios
    .post(url + "api/updateSettings", userData)
    .then((res) =>
      dispatch({
        type: SETTINGS_UPDATE,
        payload: res,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const replyContact = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminApi/replycontact`,
      data,
    });
    console.log("adsadasd",respData.data);
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const replysupport = (supportreplyData) => (dispatch) => {
  console.log(supportreplyData, "supportreplyData");
  console.log(dispatch, "dispatch");

  axios
    .post(url + "api/support_reply_admin", supportreplyData)
    .then((res) =>
      dispatch({
        type: SUPPORT_REPLY,
        payload: res,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const updatedynamic = (userData) => (dispatch) => {
  console.log("userdata ", userData);
  axios
    .post(url + "api/updatedynamic", userData)
    .then((res) =>
      dispatch({
        type: SETTINGS_UPDATE,
        payload: res,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const getTableDataDynamic = (dynobj) => (dispatch) => {
  axios
    .post(url + "cryptoapi/getTableDataDynamic", dynobj)
    .then((res) =>
      dispatch({
        type: SETTINGS_UPDATE,
        payload: res,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const Botfunction = (userData) => (dispatch) => {
  axios
    .post(url + "api/Botfunction", userData)
    .then((res) =>
      dispatch({
        type: SETTINGS_UPDATE,
        payload: res,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const SpotTradeOrderplacing = (userData) => (dispatch) => {
  axios
    .post(url + "cryptoapi/spottradeorderplacing", userData)
    .then((res) =>
      dispatch({
        type: SETTINGS_UPDATE,
        payload: res,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const getUser = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminapi/user`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const userStatusChange = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminapi/changeStatus`,
      data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.messages,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const changeTwofa = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminapi/change2Fa`,
      data,
    });
    console.log(respData);
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
      message: respData.data.messages,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getUserBalnce = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminapi/getUserBalnce`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const getAdminRevenue = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminapi/adminrevenue`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getuserAssetDetails = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminapi/getSingleUserAssetDetails`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};



export const getDeletedList = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminapi/getDeletedUserList`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};


export const getContact = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminApi/contactus`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};