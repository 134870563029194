import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';
import moment from 'moment'
// import component
import CoinDepositModal from '../partials/CoinDepositModal';
import FiatDepositModal from '../partials/FiatDepositModal';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
// import action
import { getDepositList } from '../../actions/walletAction'
import { Link } from "react-router-dom";
import { capitalize } from "../../lib/capitalize";
class Deposit extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "createdAt",
                text: "Created date",
                className: "created_date",
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <p>{moment(record.createdAt).format("DD-MM-YYYY HH:mm:ss")}</p>
                        </Fragment>
                    )
                }
            },
            {
                key: "userId",
                text: "Email",
                className: "userId",
                align: "left",
                sortable: false,
                cell: (record) => {
                    return record.userId && record.userId.email ? record.userId.email:"-";
                },
            },
            {
                key: "toaddress",
                text: "To Address",
                className: "toaddress",
                align: "left",
                sortable: false,
                width: 200,
                cell: record => {
                    if (record.paymentType == 'fiat_deposit') {
                        return record.userAssetId
                    } if (record.paymentType == 'coin_deposit') {
                        return record.toaddress
                    }
                }
            },
            {
                key: "currencySymbol",
                text: "Currency",
                className: "currency",
                align: "left",
                sortable: false
            },
            {
                key: "paymentType",
                text: "Payment Type",
                className: "currency",
                align: "left",
                sortable: false
            },
      
            {
                text: "Phone No",
                className: "currency",
                align: "left",
                sortable: false,
                cell: (record) => {
                    return record.userId && record.userId.phoneNo ? record.userId.phoneNo:"-";
                },
            },
            {
                key: "amount",
                text: "Transfer Amount",
                className: "amount",
                align: "left",
                sortable: false
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: false,
                cell: (record) => {
                    return capitalize(record.status);
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    console.log("recordrecord", record)
                    if (record.paymentType == "coin_deposit") {

                        return (
                            <Fragment>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => this.setState({ 'coinFormModal': true, 'depositRecord': record })}
                                    style={{ marginRight: '5px' }}>
                                    <i className="fa fa-check"></i>
                                </button>
                            </Fragment>
                        );
                    } else if (record.paymentType == "fiat_deposit") {
                        return (
                            <Fragment>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => this.setState({ 'fiatFormModal': true, 'depositRecord': record })}
                                    style={{ marginRight: '5px' }}>
                                    <i className="fa fa-check"></i>
                                </button>
                            </Fragment>
                        );
                    }
                }
            }

        ];

        this.state = {
            records: [],
            sortOrder: {
                column: "_id",
                order: "desc",
            },
            search: '',
            totalCount: 0,
            offset: 0,
            pageSize: 10,
            loader: false,
            coinFormModal: false,
            fiatFormModal: false,
            depositRecord: {},
            
        };


        this.config = {
            page_size: this.state.limit,
            sort: this.state.sortOrder,
            length_menu: [10, 20, 50],
            filename: "Withdraw",
            no_data_text: 'No Records found!',
            loading: true,
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };


      
        this.fetchDeposit = this.fetchDeposit.bind(this);
        this.refetchDeposit = this.refetchDeposit.bind(this);

        this.handleCloseCoinForm = this.handleCloseCoinForm.bind(this);
        this.handleCloseFiatForm = this.handleCloseFiatForm.bind(this);
    }
    addRecord() {
        window.location.href("/login")
    }
    componentDidMount() {
        this.fetchDeposit();
    };

    async fetchDeposit(filter = "") {
        this.setState({ "loader": true })
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let data = {
            timezone,
            sortOrder: this.state.sortOrder,
            filter,
            limit: this.state.pageSize,
            offset: this.state.offset,
        };
        try {
            const { status, loading, result ,totalCount} = await getDepositList(data);
            this.setState({ "loader": loading })
            if (status == 'success') {
                this.setState({ records: [], totalCount: 0 }, function() {
                    this.setState({ totalCount, records: result });
                });
            }
        } catch (err) { 
            this.setState({ "loader": false })
        }
    }

    search = data => {
        let filter = data.filter_value;
        let pageSize = data.page_size;
        let offset = (data.page_number-1)*pageSize;
        let sortOrder = data.sort_order ? data.sort_order:this.state.sortOrder;
        this.setState({ records: [], offset, pageSize, sortOrder, filter, loading: true }, function() {
            this.fetchDeposit(filter);
        }); 
    }

    handleCloseCoinForm() {
        this.setState({ coinFormModal: false })
    }

    handleCloseFiatForm() {
        this.setState({ fiatFormModal: false })
    }

    refetchDeposit() {
        this.fetchDeposit()
    }

    render() {
        const { loader, totalCount, records, coinFormModal, fiatFormModal, depositRecord } = this.state;


        return (
            <>
                <CoinDepositModal
                    isShow={coinFormModal}
                    onHide={this.handleCloseCoinForm}
                    record={depositRecord}
                    fetchData={this.refetchDeposit}
                />
                <FiatDepositModal
                    isShow={fiatFormModal}
                    onHide={this.handleCloseFiatForm}
                    record={depositRecord}
                    fetchData={this.refetchDeposit}
                />

                <div id="page-content-wrapper">
                    <div className="container-fluid">

                        <Link to="/buildepost">
                            <button

                                className="btn btn-outline-primary float-right mt-3 mr-2"
                            ><FontAwesomeIcon icon={faPlus} /> Bulk deposit
                            </button>
                        </Link>
                        <h3 className="mt-2 text-secondary">Deposit List</h3>
                        <ReactDatatable
                            config={this.config}
                            records={records}
                            columns={this.columns}
                            dynamic={true}
                            total_record={totalCount}
                            loading={loader}
                            onChange={this.search}
                        />
                    </div>
                </div>
            </>

        );
    }

}

export default withRouter(Deposit);
