// import config
import axios from '../config/axios';
import config from "../config";
import keys from "./config";
const url = keys.baseUrl;
export const getCmsList = async (params) => {
    try {
        const respData = await axios({
            'url': `/adminapi/cms/${params}`,
            'method': 'get'
        })
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false
        }
    }
}
export const getLaunchpadCmsList = async () => {
    try {
        const respData = await axios({
            'url': `/adminApi/get-launchpadcms`,
            'method': 'get'
        })
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        console.log('err',err)
        return {
            status: 'failed',
            loading: false
        }
    }
}



export const updateCms = async (data) => {
    try {
        const respData = await axios({
            'url': `/adminApi/cms`,
            'method': 'put',
            'data': data
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false
        }
    }
}
export const updateLaunchpadCms = async (data) => {
    console.log('data',data)
    try {
        const respData = await axios({
            'url': `${config.API_URL}/adminApi/updateLaunchpadCms`,
            
            'method': 'put',
            'data': data
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false
        }
    }
}

export const sliderUpdate = async (data) => {
    console.log('data',data)
    try {
        const respData = await axios({
            'url': `${config.API_URL}/adminApi/sliderUpdate`,
            
            'method': 'post',
            'data': data
        })
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
            
        }
    }
}


