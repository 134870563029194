import React, { Component, Fragment } from "react";

import classnames from "classnames";
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateProfile, getadmindetail, onEmailupdateaction } from "../../actions/userActions";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../config/index";
import isEmpty from "is-empty";
const url = keys.baseUrl;

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: "",
            name: "",
            email: "",
            phonenumber: "",
            profile: "",
            profileurl: "",
            errors: {},
            showeditbutton: 0,
            showemailchangeinputbox: 0,
            newemail: "",
            nameerr: "",
            showbutton: 0,
            oldemailotp: 0,
            newemailotp: 0,
        };
    }

    componentDidMount() {
        // getadmindetail()

        this.getData()
    };

    componentWillReceiveProps(nextProps) {

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.profile !== undefined
            && nextProps.auth.profile.data !== undefined
            && nextProps.auth.profile.data.message !== undefined) {
            toast(nextProps.auth.profile.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.profile = undefined;
        }
    }

    getData() {
        if (!window.location.hash) {
            window.location = window.location + '#loaded';
            window.location.reload();
        }

        // getadmindetail()
        //  if (this.props.auth !== undefined && this.props.auth.user !== undefined) {
        //     const id = this.props.auth.user.id;
        //     axios
        //     .get(keys.API_URL+"/adminapi/getadmindetail/"+id)
        //     .then(res => {
        //         console.log(res,'ressdcfdvcfvfv');
        //         this.setState(res.data);
        //         if(this.state.profile == ""){
        //             this.setState({profileurl : keys.baseUrl+"uploads/No_image_available.png"})
        //         }else{
        //             this.setState({profileurl : keys.baseUrl+this.state.profile})
        //         }
        //         console.log(this.state.profile);
        //     })
        //     .catch()
        // }
        axios
            .get(keys.API_URL + "/adminapi/getadmindetail")
            .then(res => {
                let value = res.data.result
                this.setState({
                    name: value.name,
                    email: value.email,
                    phonenumber: value.mobileno
                })
            }


            ).catch(err =>
                console.log("errerrerrerrerrerrerrerr", err)
            );
        console.log(this.props.auth);
    }

    handleChange = (event) => {
        this.setState({
            profileurl: URL.createObjectURL(event.target.files[0]),
            profile: event.target.files[0]
        })
        //   const data = new FormData()
        //   data.append('file', this.state.profile)
        //   axios.post(url+"api/profileupload", data, { // receive two parameter endpoint url ,form data
        //   })
        //   .then(res => { // then print response status
        //     console.log(res.statusText)
        //   })
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onProfileUpdate = e => {
        e.preventDefault();
        const updateProfile = {
            _id: this.state.id,
            name: this.state.name,
            email: this.state.email,
            phonenumber: this.state.phonenumber,
            profile: this.state.profile
        };
        if (this.state.name == "" || this.state.name == undefined) {
            this.setState({
                nameerr: "Please Enter Name"
            })
            return
        }
        this.setState({
            nameerr: ""
        })
        const data = new FormData();
        data.append('name', this.state.name);
        data.append('email', this.state.email);
        data.append('phonenumber', this.state.phonenumber);
        data.append('file', this.state.profile);
        data.append('_id', this.state.id);

        axios
            .post(keys.API_URL + "/adminapi/profileupload", updateProfile)
            .then((res) => {
                console.log("profileuploadprofileupload", res.data.success)
                if (res.data.success) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }


            })

            .catch((err) =>
                console.log("errerrerrerr", err.data)
            );

        this.getData()
    };


    onEmailupdate = e => {
        e.preventDefault();
        const updateemail = {
            _id: this.state.id,
            newemail: this.state.newemail,
            email: this.state.email,

        };
        let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (this.state.newemail == "") {
            toast("Please enter new Email", {
                position: toast.POSITION.TOP_CENTER
            });
            return
        } else {
            if (!(emailRegex.test(this.state.newemail))) {
                toast("Invalid Email", {
                    position: toast.POSITION.TOP_CENTER
                });
                return
            }
        }
        // this.props.onEmailupdateaction(updateemail);
        axios
            .post(keys.API_URL + "/adminapi/onEmailupdate", updateemail)
            .then((res) => {
                if (res.data.success) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    console.log("res.data", res.data.showbutton)
                    this.setState({ showbutton: res.data.showbutton })
                } else {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
            )
            .catch((err) =>
                console.log("onEmailupdate", err)
            );
        this.getData()
    };
    handleClick(e) {
        e.preventDefault()
        console.log("this.state.newemailotpthis.state.newemailotp", isNaN(this.state.newemailotp))

        if (isNaN(this.state.oldemailotp)) {
            toast("Enter Number", {
                position: toast.POSITION.TOP_CENTER
            });
            return
        } else {
            if ((this.state.oldemailotp).length < 6) {
                toast("Invalid Otp", {
                    position: toast.POSITION.TOP_CENTER
                });
                return
            }
        }
        const updateemail = {
            _id: this.state.id,
            newemail: this.state.newemail,
            email: this.state.email,
            oldemailotp: this.state.oldemailotp,

        };
        axios
            .post(keys.API_URL + "/adminapi/otpsendtonewemail", updateemail)
            .then((res) => {
                if (res.data.success) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    console.log("res.data", res.data.showbutton)
                    this.setState({ showbutton: res.data.showbutton })
                } else {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
            )
            .catch((err) =>
                console.log("onEmailupdate", err)
            );
    }


    handleClicknewemailotp(e) {
        e.preventDefault()
        console.log("this.state.newemailotpthis.state.newemailotp", isNaN(this.state.newemailotp))
        if (isNaN(this.state.newemailotp)) {
            toast("Enter a Number", {
                position: toast.POSITION.TOP_CENTER
            });
            return
        } else {
            if ((this.state.newemailotp).length < 6) {
                toast("Invalid Otp", {
                    position: toast.POSITION.TOP_CENTER
                });
                return
            }
        }
        const updateemail = {
            _id: this.state.id,
            newemail: this.state.newemail,
            email: this.state.email,
            newemailotp: this.state.newemailotp,

        };
        axios
            .post(keys.API_URL + "/adminapi/updateadminemail", updateemail)
            .then((res) => {
                if (res.data.success) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    console.log("res.data", res.data.showbutton)
                    this.setState({ showbutton: res.data.showbutton })
                    window.location.reload()
                } else {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
            )
            .catch((err) =>
                console.log("onEmailupdate", err)
            );
    }

    sendotp(e) {
        e.preventDefault()
        console.log("this.state.newemailotpthis.state.newemailotp", isNaN(this.state.addmobile))
        if (isNaN(this.state.addmobile)) {
            toast("Enter a Number", {
                position: toast.POSITION.TOP_CENTER
            });
            return
        } else {

            var len = Math.ceil(Math.log(this.state.addmobile + 1) / Math.LN10);
            console.log("this.state.newemailotpthis.state.newemailotp", len)

            if (len >= 14 || len == 0 || len == "NaN") {
                toast("Invalid Mobile no", {
                    position: toast.POSITION.TOP_CENTER
                });
                return

            }

        }
        const updateemail = {
            _id: this.state.id,
            type: 1,
            sendotptono: this.state.addmobile,
            addmobileotp: this.state.addmobileotp,

        };
        axios
            .post(keys.API_URL + "/adminapi/sendotp", updateemail)
            .then((res) => {
                if (res.data.success) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    console.log("res.data", res.data.showbutton)
                    this.setState({ showbutton: res.data.showbutton })

                } else {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
            )
            .catch((err) =>
                console.log("onEmailupdate", err)
            );
    }

    addmobileno(e) {
        e.preventDefault()
        console.log("this.state.newemailotpthis.state.newemailotp", isNaN(this.state.addmobileotp))
        if (isNaN(this.state.addmobileotp)) {
            toast("Enter a Number", {
                position: toast.POSITION.TOP_CENTER
            });
            return
        } else {
            if ((this.state.addmobileotp).length < 6) {
                toast("Invalid Otp", {
                    position: toast.POSITION.TOP_CENTER
                });
                return
            }
        }
        const updateemail = {
            addmobile: this.state.addmobile,
            addmobileotp: this.state.addmobileotp,

        };
        axios
            .post(keys.API_URL + "/adminapi/updatemobileno", updateemail)
            .then((res) => {
                if (res.data.success) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    console.log("res.data", res.data.showbutton)
                    this.setState({ showbutton: res.data.showbutton })
                    window.location.reload()
                } else {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
            )
            .catch((err) =>
                console.log("onEmailupdate", err)
            );
    }

    render() {
        const { errors } = this.state;
        return (
            <>

                <div id="page-content-wrapper">
                    <div className="container-fluid">


                        <h3 className="mt-2 text-secondary">Edit Profile</h3>
                        <form onSubmit={this.onProfileUpdate} id="update-profile">
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Name</label>
                                </div>
                                <div className="col-md-6">

                                    <input
                                        onChange={this.onChange}
                                        value={this.state.name}
                                        id="name"
                                        type="text"
                                        // error={errors.name}

                                        className={classnames("form-control")}
                                    />
                                    <span className="text-danger">{this.state.nameerr}</span>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="email">Email</label>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.email}
                                        id="email"
                                        type="text"
                                        // error={errors.email}
                                        className={classnames("form-control",)}
                                        disabled />

                                    {/* <span className="text-danger">{errors.email}</span> */}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="phonenumber">Phone Number</label>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.phonenumber}
                                        id="phonenumber"
                                        type="text"
                                        // error={errors.phonenumber}
                                        className={classnames("form-control",)}
                                        disabled />
                                    {/* <span className="text-danger">{errors.phonenumber}</span> */}
                                </div>
                            </div>

                            {/*}<div className="row mt-2">
                                        <div className="col-md-3">
                                        <br/>
                                        <label htmlFor="profile">Profile Image</label>
                                        </div>
                                        <div className="col-md-6">
                                        <input type="file" onChange={this.handleChange}
                                        />
                                        <img width="100px" src={this.state.profileurl} />

                                        </div>
                                    </div>
                                    */}

                        </form>
                        <br />
                        {/* {this.state.showeditbutton==1&& */}
                        <button
                            form="update-profile"
                            type="submit"
                            className="btn btn-primary">
                            Update Profile
                        </button>
                        {/* } */}
                    </div>
                    <div className="container-fluid">


                        <h3 className="mt-2 text-secondary">Change Email</h3>
                        <form noValidate onSubmit={this.onEmailupdate} id="email-change">

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="email">Current Email</label>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.email}
                                        id="email"
                                        type="text"
                                        // error={errors.email}
                                        className={classnames("form-control")
                                        }
                                        disabled />

                                    {/* <span className="text-danger">{errors.email}</span> */}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="phonenumber">New Email</label>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.newemail}
                                        id="newemail"
                                        type="text"
                                        disabled={this.state.showbutton == 0 ? false : true}
                                        className={classnames("form-control")} />
                                    {/* <span className="text-danger">{errors.newemail}</span> */}
                                </div>
                            </div>
                            {this.state.showbutton == 1 &&
                                <>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="phonenumber">Current Email OTP</label>
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.oldemailotp}
                                                id="oldemailotp"
                                                type="number"

                                                className={classnames("form-control")} />
                                            {/* <span className="text-danger">{errors.newemail}</span> */}
                                        </div>
                                    </div>

                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={(e) => this.handleClick(e)}
                                    >
                                        Submit
                                    </button>
                                </>
                            }


                            {this.state.showbutton == 2 &&
                                <>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="phonenumber">New Email OTP</label>
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.newemailotp}
                                                id="newemailotp"
                                                type="number"

                                                className={classnames("form-control")} />
                                            {/* <span className="text-danger">{errors.newemail}</span> */}
                                        </div>
                                    </div>

                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={(e) => this.handleClicknewemailotp(e)}
                                    >
                                        Update Email
                                    </button>
                                </>
                            }
                            {/*}<div className="row mt-2">
                                        <div className="col-md-3">
                                        <br/>
                                        <label htmlFor="profile">Profile Image</label>
                                        </div>
                                        <div className="col-md-6">
                                        <input type="file" onChange={this.handleChange}
                                        />
                                        <img width="100px" src={this.state.profileurl} />

                                        </div>
                                    </div>
                                    */}

                        </form>
                        <br />
                        {this.state.showbutton == 0 &&
                            <button
                                form="email-change"
                                type="submit"
                                className="btn btn-primary">
                                Change Email
                            </button>
                        }
                    </div>
                    {console.log("phonenumberphonenumberphonenumber", this.state.phonenumber)}
                    {this.state.phonenumber == "" &&
                        <div className="container-fluid">


                            <h3 className="mt-2 text-secondary">Add Mobile No </h3>
                            <form noValidate onSubmit={this.onEmailupdate} id="email-change">

                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="email">Add Mobile NO</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.addmobile}
                                            id="addmobile"
                                            type="text"
                                            // error={errors.email}
                                            placeholder="+918765432190"
                                            className={classnames("form-control")
                                            }
                                        />

                                        {/* <span className="text-danger">{errors.email}</span> */}
                                        <br></br>     <button
                                            form="email-change"
                                            type="button"
                                            onClick={(e) => this.sendotp(e)}
                                            className="btn btn-primary">
                                            Send OTP
                                        </button>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="phonenumber">OTP Number</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.addmobileotp}
                                            id="addmobileotp"
                                            type="number"

                                            className={classnames("form-control")} />
                                        {/* <span className="text-danger">{errors.newemail}</span> */}
                                    </div>
                                </div>


                            </form>
                            <br />

                            <button
                                form="email-change"
                                type="button"
                                onClick={(e) => this.addmobileno(e)}
                                className="btn btn-primary">
                                Add Mobile No
                            </button>

                        </div>
                    }
                    {this.state.phonenumber != "" &&
                        <div className="container-fluid">


                            <h3 className="mt-2 text-secondary">Update Mobile No </h3>
                            <form noValidate onSubmit={this.onEmailupdate} id="email-change">

                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="email">Enter Mobile NO</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.addmobile}
                                            id="addmobile"
                                            type="text"
                                            // error={errors.email}
                                            placeholder="+918765432190"
                                            className={classnames("form-control")
                                            }
                                        />

                                        {/* <span className="text-danger">{errors.email}</span> */}
                                        <br></br>     <button
                                            form="email-change"
                                            type="button"
                                            onClick={(e) => this.sendotp(e)}
                                            className="btn btn-primary">
                                            Send OTP
                                        </button>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-3">
                                        <label htmlFor="phonenumber">OTP Number</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            onChange={this.onChange}
                                            value={this.state.addmobileotp}
                                            id="addmobileotp"
                                            type="number"

                                            className={classnames("form-control")} />
                                        {/* <span className="text-danger">{errors.newemail}</span> */}
                                    </div>
                                </div>


                            </form>
                            <br />

                            <button
                                form="email-change"
                                type="button"
                                onClick={(e) => this.addmobileno(e)}
                                className="btn btn-primary">
                                Update Mobile No
                            </button>

                        </div>
                    }

                </div>


                <ToastContainer />

            </>
        );
    }

}

Profile.propTypes = {
    updateProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateProfile }
)(withRouter(Profile));
