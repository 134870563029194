import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import authLogo from "../../images/authLogo2.png";
import browser from 'browser-detect'
// import action
import { login,getGeoInfoData } from "../../actions/admin";

// import lib
import { toastAlert } from "../../lib/toastAlert";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      twofa: false,
      twofa_code: "",
      errors: {},
      loginHistory: {}
    };
  }


  getGeoInfo = async () => {
    try {
        let { result } = await getGeoInfoData();
        console.log("🚀 ~ file: Login.js ~ line 44 ~ Login ~ getGeoInfo= ~ result", result)

        const browserResult = browser();
        this.setState({
            loginHistory:
                ({
                    countryName: result.country_name,
                    countryCode: result.country_calling_code,
                    ipaddress: result.ip,
                    region: result.region,
                    broswername: browserResult.name,
                    ismobile: browserResult.mobile,
                    os: browserResult.os,
                })
        })
    }
    catch (err) {
    }
};

  componentDidMount() {
    // if (this.props.auth.isAuthenticated) {
    //     this.props.history.push("/dashboard");
    // }

      this.getGeoInfo()
  
  }

  // componentWillReceiveProps(nextProps) {
  //     if (nextProps.auth.isAuthenticated) {
  //         this.props.history.push("/dashboard");
  //     }

  //     if (nextProps.errors) {
  //         this.setState({
  //             errors: nextProps.errors
  //         });
  //     }
  // }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { dispatch, history } = this.props;
    const reqData = {
      email: this.state.email,
      password: this.state.password,
      twofa_code: this.state.twofa_code,
      //   twofa: this.state.twofa,
      loginHistory: this.state.loginHistory
    };
    try {
      const { status, loading, message, error, twoFaStatus } = await login(
        reqData,
        dispatch
      );
      if (status == "success") {
        if (twoFaStatus == "enabled") {
          this.setState({ twofa: true });
          toastAlert("success", "Please enter two fa code", "login");
          return;
        }
        history.push("/profile");
        toastAlert("success", message, "login");
      } else {
        if (error) {
          this.setState({ errors: error });
        }
        toastAlert("error", message, "login");
      }
    } catch (err) {}
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-4 mx-auto mt-5 card shadow-lg">
            <div className="card-body p-2">
              <center className="pt-3">
                <img src={authLogo} className="img-fluid" alt="" />
              </center>
              <h2 className="text-center text-primary mt-3">Login</h2>
              <form noValidate onSubmit={this.onSubmit} className="white">
                <label htmlFor="email">Email</label>
                <input
                  onChange={this.onChange}
                  value={this.state.email}
                  error={errors.email}
                  id="email"
                  type="email"
                  className={classnames("form-control", {
                    invalid: errors.email,
                  })}
                />
                <span className="text-danger">{errors.email}</span>
                <br />
                <label htmlFor="password">Password</label>
                <input
                  onChange={this.onChange}
                  value={this.state.password}
                  error={errors.password}
                  id="password"
                  type="password"
                  className={classnames("form-control", {
                    invalid: errors.password,
                  })}
                />
                <span className="text-danger">{errors.password}</span>

                <br />
                {this.state.twofa == true && (
                  <>
                    <label htmlFor="2FA">2FA Code</label>
                    <input
                      onChange={this.onChange}
                      value={this.state.twofa_code}
                      error={errors.twofa}
                      id="twofa_code"
                      type="text"
                      className={classnames("form-control", {
                        invalid: errors.twofa,
                      })}
                    />
                    <span className="text-danger">{errors.twofa}</span>{" "}
                  </>
                )}

                <p className="text-center pb-0 mt-2">
                  <button
                    type="submit"
                    className="btn btn-large btn-primary mt-2 px-5"
                  >
                    Login
                  </button>
                </p>
                <p className="grey-text text-darken-1">
                  Don't have an account?{" "}
                  <Link to="/forgot">Forgot Password?</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, null)(Login);
