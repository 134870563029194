import React, { Component, Fragment } from "react";
import classnames from "classnames";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateChangepassword } from "../../actions/userActions";
import axios from "axios";
import { toastAlert } from '../../lib/toastAlert';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
const url = keys.baseUrl;
class Changepassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: "",
            oldpassword: "",
            password: "",
            password2: "",
            errors: {},
            otp: "",
            otpnew: "",
            name: "",
            email: "",
            showPassword: false,
            showPassword1: false,
            showPassword2: false,
        };
    }

    componentDidMount() {
        this.getData()
    };

    getData() {
        if (this.props.auth !== undefined && this.props.auth.user !== undefined) {
            const id = this.props.auth.user._id;
            this.setState({ email: this.props.auth.user.email })

            axios
                .get(url + "adminapi/getadmindetail")
                .then(res => {
                    this.setState({ email: res.data.result.email });
                })
                .catch()
        }
        console.log(this.props.auth);
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.Changepassword !== undefined
            && nextProps.auth.Changepassword.data !== undefined
            && nextProps.auth.Changepassword.data.message !== undefined) {
            toast(nextProps.auth.Changepassword.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.Changepassword = undefined;
        }
    }
    onChange = e => {
        let clearError = "", id = e.target.id
        if (id == "oldpassword")
            clearError = ({ ...this.state.errors, ["oldPassword"]: "" })
        else if (id == "password")
            clearError = ({ ...this.state.errors, ["password"]: "" })
        else if (id == "password2")
            clearError = ({ ...this.state.errors, ["confirmPassword"]: "" })
        this.setState({ [id]: e.target.value })
        console.log("oldpasswordoldpasswordoldpassword", clearError);
        if (clearError)
            this.setState({ errors: clearError });

    };
    onsendOtp = e => {
        e.preventDefault();
        // if(typeof this.state.phone != "undefined" && this.state.phone!=""){
        const emaildata = {
            _id: this.props.auth.user.id,
            // email: this.state.email
        };
        console.log("emaildata", emaildata);
        axios
            .post(url + "api/sendotppasswordchange", emaildata)
            .then(res => {
                if (res.data.success) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    this.setState({ errors: "" })
                } else {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch();

    }

    onChangepasswordUpdate = async e => {
        e.preventDefault();
        const updatechangepassword = {
            _id: this.props.auth.user._id,
            oldPassword: this.state.oldpassword,
            password: this.state.password,
            confirmPassword: this.state.password2,
            otpnew: this.state.otpnew
        };
        let { status, loading, error, message } = await updateChangepassword(updatechangepassword);
        if (error) {
            this.setState({ errors: error })
            console.log("errorerrorerrorerror", error)
        }
        if (status) {
            toastAlert('success', message, 'AdminSideAddModal')
            // window.location.reload()
            this.setState({ errors: {} })
            this.setState({ oldpassword: "", password: "", password2: "" })

        }
    };

    render() {
        const { errors } = this.state;
        return (
            <>

                <div id="page-content-wrapper">
                    <div className="container-fluid">


                        <h3 className="mt-2 text-secondary">Change Password</h3>


                        <form noValidate onSubmit={this.onsendOtp} id="otp-form">
                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="password">Email</label>
                                </div>
                                <div className="col-md-6">
                                    {this.state.email} {" "}
                                    {/* <button form="otp-form" className="btn btn-primary">Send OTP</button> */}

                                </div>
                            </div>

                        </form>


                        <form noValidate onSubmit={this.onChangepasswordUpdate} id="update-Changepassword">

                            {/* <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Otp</label>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.otpnew}
                                        id="otpnew"
                                        type="text"
                                        error={errors.otpnew}
                                        className={classnames("form-control", {
                                            invalid: errors.otpnew
                                        })} />
                                    <span className="text-danger">{errors.otpnew}</span>
                                </div>
                            </div> */}



                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="name">Old Password</label>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group">

                                        <input
                                            onChange={this.onChange}
                                            value={this.state.oldpassword}
                                            id="oldpassword"
                                            type={`${this.state.showPassword ? "text" : "password"}`}
                                            error={errors.oldpassword}
                                            className={classnames("form-control", {
                                                invalid: errors.oldpassword
                                            })}
                                        />
                                        <span
                                            className="input-group-text"
                                            onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                                        >
                                            <i
                                                className={`fa fa-eye-slash ${this.state.showPassword ? "d-none" : ""
                                                    }`}
                                            ></i>
                                            <i
                                                className={`fa fa-eye ${this.state.showPassword ? "" : "d-none"
                                                    }`}
                                            ></i>

                                        </span>
                                    </div>


                                    <span className="text-danger">{errors.oldPassword}</span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="password">New Password</label>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group">

                                        <input
                                            onChange={this.onChange}
                                            value={this.state.password}
                                            id="password"
                                            type={`${this.state.showPassword1 ? "text" : "password"}`}
                                            error={errors.password}
                                            className={classnames("form-control", {
                                                invalid: errors.password
                                            })} />
                                        <span
                                            className="input-group-text"
                                            onClick={() => this.setState({ showPassword1: !this.state.showPassword1 })}
                                        >
                                            <i
                                                className={`fa fa-eye-slash ${this.state.showPassword1 ? "d-none" : ""
                                                    }`}
                                            ></i>
                                            <i
                                                className={`fa fa-eye ${this.state.showPassword1 ? "" : "d-none"
                                                    }`}
                                            ></i>

                                        </span>


                                    </div>
                                    <span className="text-danger">{errors.password}</span>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-md-3">
                                    <label htmlFor="password2">Confirm Password</label>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-group">

                                        <input
                                            onChange={this.onChange}
                                            value={this.state.password2}
                                            id="password2"
                                            type={`${this.state.showPassword2 ? "text" : "password"}`}
                                            error={errors.password2}
                                            className={classnames("form-control", {
                                                invalid: errors.password2
                                            })} />
                                        <span
                                            className="input-group-text"
                                            onClick={() => this.setState({ showPassword2: !this.state.showPassword2 })}
                                        >
                                            <i
                                                className={`fa fa-eye-slash ${this.state.showPassword2 ? "d-none" : ""
                                                    }`}
                                            ></i>
                                            <i
                                                className={`fa fa-eye ${this.state.showPassword2 ? "" : "d-none"
                                                    }`}
                                            ></i>

                                        </span>

                                    </div>
                                    <span className="text-danger">{errors.confirmPassword}</span>
                                </div>
                            </div>
                        </form>
                        <br />
                        <button
                            form="update-Changepassword"
                            type="submit"
                            className="btn btn-primary">
                            Update Password
                        </button>
                    </div>
                </div>
                <ToastContainer />
            </>

        );
    }

}

Changepassword.propTypes = {
    updateChangepassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateChangepassword }
)(withRouter(Changepassword));
