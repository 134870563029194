// import lib
import isEmpty from '../../../lib/isEmpty';

const validation = value => {
    let errors = {};

    if (isEmpty(value.pairId)) {
        errors.pairId = "Pair field is Required"
    }

    if (isEmpty(value.side)) {
        errors.side = "Side field is Required"
    } else if (!Array.isArray(value.side)) {
        errors.side = "Type field is required";
    } else if (!(value.side.some(r => ['buy', 'sell'].includes(r)))) {
        errors.side = "invalid side"
    }

    if (value.side && value.side.includes('buy')) {
        if (isEmpty(value.buyStartPrice)) {
            errors.buyStartPrice = "Start Price field is Required"
        } else if (isNaN(value.buyStartPrice)) {
            errors.buyStartPrice = "Start Price only numeric value"
        } else if (parseFloat(value.buyStartPrice) <= 0) {
            errors.buyStartPrice = "Start Price only positive numeric value"
        }

        if (isEmpty(value.buyEndPrice)) {
            errors.buyEndPrice = "End Price field is Required"
        } else if (isNaN(value.buyEndPrice)) {
            errors.buyEndPrice = "End Price only numeric value"
        } else if (parseFloat(value.buyEndPrice) <= 0) {
            errors.buyEndPrice = "End Price only positive numeric value"
        } else if (!(isEmpty(value.buyEndPrice)) && parseFloat(value.buyEndPrice) >= parseFloat(value.buyStartPrice)) {
            errors.buyEndPrice = "End price should be lesser than start price"
        }
    }

    if (value.side && value.side.includes('sell')) {

        if (isEmpty(value.sellStartPrice)) {
            errors.sellStartPrice = "Start Price field is Required"
        } else if (isNaN(value.sellStartPrice)) {
            errors.sellStartPrice = "Start Price only numeric value"
        } else if (parseFloat(value.sellStartPrice) <= 0) {
            errors.sellStartPrice = "Start Price only positive numeric value"
        }

        if (isEmpty(value.sellEndPrice)) {
            errors.sellEndPrice = "End Price field is Required"
        } else if (isNaN(value.sellEndPrice)) {
            errors.sellEndPrice = "End Price only numeric value"
        } else if (parseFloat(value.sellEndPrice) <= 0) {
            errors.sellEndPrice = "End Price only positive numeric value"
        } else if (!(isEmpty(value.sellEndPrice)) && parseFloat(value.sellEndPrice) <= parseFloat(value.sellStartPrice)) {
            errors.sellEndPrice = "End price should be higher than start price"
        }
    }

    if (isEmpty(value.startQuantity)) {
        errors.startQuantity = "Start Quantity field is Required"
    } else if (isNaN(value.startQuantity)) {
        errors.startQuantity = "Start Quantity only numeric value"
    } else if (parseFloat(value.startQuantity) <= 0) {
        errors.startQuantity = "Start Quantity only positive numeric value"
    }

    if (isEmpty(value.endQuantity)) {
        errors.endQuantity = "End Quantity field is Required"
    } else if (isNaN(value.endQuantity)) {
        errors.endQuantity = "End Quantity only numeric value"
    } else if (parseFloat(value.endQuantity) <= 0) {
        errors.endQuantity = "End Quantity only positive numeric value"
    } else if (!(isEmpty(value.endQuantity)) && parseFloat(value.startQuantity) > parseFloat(value.endQuantity)) {
        errors.endQuantity = "End Quantity should be higher than Start Quantity"
    }

    if (isEmpty(value.count)) {
        errors.count = "Count field is Required"
    } else if (isNaN(value.count)) {
        errors.count = "Count only numeric value"
    } else if (parseFloat(value.count) <= 0) {
        errors.count = "Count only positive numeric value"
    }

    return errors;
}

export const botUsrValid = value => {
    let errors = {};

    if (isEmpty(value.firstName)) {
        errors.firstName = "First Name field is Required"
    }

    if (isEmpty(value.lastName)) {
        errors.lastName = "Last Name field is Required"
    }

    if (isEmpty(value.email)) {
        errors.email = "Email field is Required"
    }

    return errors;
}

export default validation;