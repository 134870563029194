import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { createBrowserHistory } from 'history';
import Dashboard from "./components/pages/Dashboard";
import React, { Component } from "react";
import Login from "./components/auth/Login";
import Forgot from "./components/auth/Forgot";
import ResetPassword from "./components/auth/ResetPassword";
import NotFound from "./components/layout/NotFound";
import { Provider } from "react-redux";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Register from "./components/auth/Register";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import Notification from "./components/pages/notificationHistory";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import List from "@material-ui/icons/List";
import LoginHistory from "./components/partials/LoginHistory.js";
// import component
import ConditionRoute from "./components/Route/ConditionRoute";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "../node_modules/font-awesome/css/font-awesome.css";
import "../node_modules/jquery/dist/jquery.min";
import "../node_modules/popper.js/dist/popper";
import User from "./components/pages/Users";
import Bonus from "./components/pages/Bonus";
import Emailtemplates from "./components/pages/Emailtemplates";
import Pairmanagement from "./components/pages/Pairmanagement";
import Cms from "./components/pages/CmsPage";
import Profile from "./components/pages/Profile.jsx";
import Settings from "./components/pages/Settings";
import Changepassword from "./components/pages/Changepassword";

import Withdraw from "./components/pages/Withdraw";
import Deposit from "./components/pages/Deposit";
import builkdepost from "./components/pages/builkdepost.js";
import builkWithdraw from "./components/pages/bulkWithdraw.js";

import Userbalance from "./components/pages/Userbalance";
import assetsDetails from "./components/pages/userAssetsList";
import Liquidated from "./components/pages/Liquidated";
import priceConversion from "./components/pages/priceConversion";
import Perpetual from "./components/pages/Perpetual";
import Contactus from "./components/pages/Contactus";
import Chat from "./components/pages/Chat";
import Newsletter from "./components/pages/Newsletter";
import SupportCategory from "./components/pages/supportCategory";
import Support from "./components/pages/Support";
import SupportReply from "./components/pages/SupportReply";
import Currency from "./components/pages/Currency";
import TradeHistory from "./components/pages/Tradehistory";
import Closedpositions from "./components/pages/Closedpositionshistory";
import OrderHistory from "./components/pages/Orderhistory";
import passBookHistory from "./components/pages/PassBookHistory";

import PerpetualOrderHistory from "./components/pages/perputualOrderHoitory";
import PerpetualTradeHistory from "./components/pages/perpetualTradeHistory";
import FeeSettings from "./components/pages/Feesettings.jsx";
import Assets from "./components/pages/AssetsExchange.jsx";
import Category from "./components/pages/category.jsx";
import Subcategory from "./components/pages/Subcategory.jsx";
import Anouncement from "./components/pages/anouncement";
import Article from "./components/pages/Article.jsx";
import Staking from "./components/pages/Staking";

import HelpCentreCategory from "./components/pages/HelpCentrecategory.jsx";
import HelpCentreArticle from "./components/pages/HelpcentreArticle.jsx";
import Kyc from "./components/pages/Kyc";
import kycupdate from "./components/partials/KycUpdate.js";
import Tradingbot from "./components/pages/Tradingbot";
import Tradingbot_old from "./components/pages/Tradingbot_old";
import Launchpad from "./components/pages/Launchpad";

import SiteSettingPage from "./components/pages/SiteSettingPage";
import AdminController from "./components/pages/AdminController";
import LanguagePage from "./components/pages/LanguagePage";
import FaqCategory from "./components/pages/FaqCategory";
import FaqPage from "./components/pages/Faq";
import perpetualTradeHistory from "./components/pages/perpetualTradeHistory";
import P2PPairmanagement from "./components/pages/P2PPairmanagement";
import P2PChat from "./components/pages/p2pchat";
import P2PTrade from "./components/pages/p2ptrade";
import P2PDispute from "./components/pages/p2pdispute";
import p2ptradeview from "./components/pages/p2ptradeview";
import p2pDisputechat from "./components/pages/p2pDisputeChat";
import adminrevenue from "./components/pages/adminrevenue";
import LaunchpadCms from "./components/pages/LaunchpadCms.jsx";
import Airdrop from "./components/pages/Airdrop";
import Airdroplist from "./components/pages/Airdroplist";
import SiteMap from "./components/pages/siteMap";
import StakeOrderHist from "./components/pages/StakeOrderHist";
import StakeSettleHist from "./components/pages/StakeSettleHist";

import FeesOwnToken from "./components/pages/FeesOwnToken";
import ReferralCommission from "./components/pages/ReferralCommission";
import twofactor from "./components/pages/twofactor";
import UserKycHistory from "./components/pages/kychistory";
import Layout from "./components/pages/Layout";
import deletedAccountList from "./components/pages/deletedAccountList";
if (localStorage.getItem("admin_token")) {
  const token = localStorage.getItem("admin_token");
  // setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));

}

let userData = store.getState();

const history = createBrowserHistory();
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ToastContainer />
        <Router basename={"/admin"} history={history}>
          <div className="App">
            <Switch>
              <ConditionRoute exact path="/" component={Login} type="auth" />
              <ConditionRoute
                exact
                path="/register"
                component={Register}
                type="auth"
              />
              <ConditionRoute
                exact
                path="/login"
                component={Login}
                type="auth"
              />
              <ConditionRoute
                exact
                path="/forgot"
                component={Forgot}
                type="auth"
              />
              <ConditionRoute
                exact
                path="/resetPassword/:authToken"
                component={ResetPassword}
                type="auth"
              />
              <Switch>
                {/*{ dashboardRoutes && dashboardRoutes.length > 0 && dashboardRoutes.map((item,key)=>{
                                    // console.log(item,'itemmmmmmmmmmmmmmmmmmmmmmmm')
                                    // alert(userData && userData.auth && userData.auth.user[item.name])
                                    // alert(userData && userData.auth && userData.auth.user[item.name])
                                    console.log("----userData.auth.user[item.name]",userData.auth.user[item.name],userData.auth.user, item.name,key)

                                    if((userData && userData.auth && userData.auth.user[item.name] ==true ) || item.name=="Dashboard" || userData.auth.user.role == 'superadmin'){
                                          // if(item.name=="SideSetting"){
                                          //   console.log(item,'SideSettingSideSettingSideSettingSideSetting')
                                          //   }
                                        return(
                                            <ConditionRoute exact path={item.path} component={item.component} type={item.type} />
                                         )                                        
                                    }else{
                                        // alert("else")
                                        return <Redirect to="/dashboard" />  
                                    }
                                   })
                                }*/}
                {userData && userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/dashboard"
                    component={Dashboard}
                    Layout={Layout}
                    type={"private"}
                  />
                ) : (
                  ""
                )}
                <ConditionRoute
                  exact
                  Layout={Layout}
                  path="/login-history"
                  component={LoginHistory}
                  type="private"
                />
                {userData.auth.user.SideSetting == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/site-setting"
                    component={SiteSettingPage}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.deleteuserList == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/deleted-users-list"
                    component={deletedAccountList}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.sitemap == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/site-map"
                    component={SiteMap}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.CmsPage == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/cms"
                    component={Cms}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.CmsPage == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/cms/:stage"
                    component={Cms}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.FaqCategory == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/faq-category"
                    component={FaqCategory}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.Faq == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/faq"
                    component={FaqPage}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {/*<PrivateRoute exact path="/dashboard" component={Dashboard} /> */}
                {userData.auth.user.User == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/users"
                    component={User}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                <ConditionRoute
                  exact
                  path="/Bonus"
                  component={Bonus}
                  type={"private"}
                  Layout={Layout}
                />
                <ConditionRoute exact path="/assets" component={Assets} />
                {userData.auth.user.EmailTemplate == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/emailtemplates"
                    component={Emailtemplates}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                <ConditionRoute exact Layout={Layout} path="/profile" component={Profile} />
                <ConditionRoute exact Layout={Layout} path="/settings" component={Settings} />
                <ConditionRoute
                  exact
                  Layout={Layout}
                  path="/changepassword"
                  component={Changepassword}

                />
                <ConditionRoute
                  exact
                  path="/history/stakeOrder"
                  component={StakeOrderHist}
                  Layout={Layout}
                />
                <ConditionRoute
                  exact
                  Layout={Layout}
                  path="/history/stakeSettlement"
                  component={StakeSettleHist}

                />
                <ConditionRoute
                  exact
                  path="/perpetual"
                  component={Perpetual}
                  type={"private"}
                  Layout={Layout}
                />
                {/*{ / priceCNVlist  --  Price Conversion List  / }*/}
                {userData.auth.user.PriceConversation == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/priceCNVlist"
                    component={priceConversion}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.ContactUs == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/contactus"
                    component={Contactus}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                <ConditionRoute exact path="/chat" component={Chat} Layout={Layout} />
                {userData.auth.user.Announcement == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/anouncement"
                    component={Anouncement}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.Newsletter == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/newsletter"
                    component={Newsletter}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.SupportCategory == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/support-category"
                    component={SupportCategory}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.Support == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/support"
                    component={Support}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.Support == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/support_reply/:id"
                    component={SupportReply}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.Language == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/language"
                    component={LanguagePage}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.Kyc == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/kyc"
                    component={Kyc}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.Kyc == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/kychistort/:id"
                    component={UserKycHistory}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.Currency == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/currency"
                    component={Currency}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                <PrivateRoute
                  exact
                  path="/closedpositions"
                  component={Closedpositions}
                  type={"private"}
                  Layout={Layout}
                />

                {userData.auth.user.p2pcommissionhistory == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/adminrevenue"
                    component={adminrevenue}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.p2ptradehistory == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute exact path="/p2ptrade" component={P2PTrade} Layout={Layout} />
                ) : (
                  ""
                )}

                {userData.auth.user.p2pchathistory == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute exact path="/p2pchat" component={P2PChat} Layout={Layout} />
                ) : (
                  ""
                )}

                {userData.auth.user.p2pdisputelist == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/p2pdispute"
                    component={P2PDispute}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}

                <ConditionRoute
                  exact
                  path="/p2ptradeview/:id"
                  component={p2ptradeview}
                  Layout={Layout}
                />

                <ConditionRoute
                  exact
                  path="/p2pdisputechat/:id"
                  component={p2pDisputechat}
                  Layout={Layout}
                />

                {userData.auth.user.SpotOrderHistory == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/orderhistory"
                    component={OrderHistory}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.SpotTradeHistory == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/tradehistory"
                    component={TradeHistory}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                <ConditionRoute
                  exact
                  path="/perpetual-Trade-History"
                  component={perpetualTradeHistory}
                  type={"private"}
                  Layout={Layout}
                />
                <ConditionRoute
                  exact
                  path="/perpetual-Order-History"
                  component={PerpetualOrderHistory}
                  type={"private"}
                  Layout={Layout}
                />
                <ConditionRoute
                  exact
                  path="/passbookHistory/:uniqueId/:currecnySymbol"
                  component={passBookHistory}
                  type={"private"}
                  Layout={Layout}
                />
                <ConditionRoute
                  exact
                  path="/passbookHistory/:uniqueId"
                  component={passBookHistory}
                  type={"private"}
                  Layout={Layout}
                />

                <ConditionRoute
                  exact
                  path="/notification"
                  component={Notification}
                  type={"private"}
                  Layout={Layout}
                />

                <ConditionRoute
                  exact
                  path="/feesettings"
                  component={FeeSettings}
                  type={"private"}
                  Layout={Layout}
                />

                {userData.auth.user.UserBalance == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/Userbalance"
                    component={Userbalance}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.UserBalance == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/assetsDetails/:userId"
                    component={assetsDetails}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.WithdrawList == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/withdraw"
                    component={Withdraw}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.DepositList == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/deposit"
                    component={Deposit}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.DepositList == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/buildepost"
                    component={builkdepost}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {/* <ConditionRoute exact path="/liquidated" component={Liquidated}type={"private"} /> 
                                 <ConditionRoute exact path="/subcategory" component={Subcategory} type={"private"}/>
                                 */}
                {userData.auth.user.BlogCategory == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/category"
                    component={Category}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.BlogArticle == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/article"
                    component={Article}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                <ConditionRoute
                  exact
                  path="/P2PPairmanagement"
                  component={P2PPairmanagement}
                  Layout={Layout}
                />
                <ConditionRoute
                  exact
                  path="/Pairmanagement"
                  component={Pairmanagement}
                  type={"private"}
                  Layout={Layout}
                />
                <ConditionRoute
                  exact
                  path="/HelpCentreCategory"
                  component={HelpCentreCategory}
                  type={"private"}
                  Layout={Layout}
                />
                <ConditionRoute
                  exact
                  path="/HelpCentreArticle"
                  component={HelpCentreArticle}
                  type={"private"}
                  Layout={Layout}
                />
                {userData.auth.user.TradingBot == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/Tradingbot"
                    component={Tradingbot}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.TradingBot == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/Tradingbot_old"
                    component={Tradingbot_old}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.TradingBot == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/Staking"
                    component={Staking}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.Launchpad == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/launchpad"
                    component={Launchpad}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.LaunchpadCms == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/launchpadcms"
                    component={LaunchpadCms}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                <ConditionRoute
                  exact
                  path="/AdminController"
                  component={AdminController}
                  type={"private"}
                  Layout={Layout}
                />
                <ConditionRoute
                  exact
                  path="/kyc"
                  component={Kyc}
                  type={"private"}
                  Layout={Layout}
                />
                <PrivateRoute
                  exact
                  path="/kycupdate/:id"
                  component={kycupdate}
                  Layout={Layout}
                />
                <PrivateRoute exact path="/2fa" component={twofactor} />

                {userData.auth.user.airdrop == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/airdrop"
                    component={Airdrop}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                <ConditionRoute
                  exact
                  path="/airdroplist"
                  component={Airdroplist}
                  type={"private"}
                  Layout={Layout}
                />

                {userData.auth.user.feeandrefferal == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/feesowntoken"
                    component={FeesOwnToken}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.refferalcommisonhistory == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/referralcommission"
                    component={ReferralCommission}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.WithdrawList == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/bulkWithdraw"
                    component={builkWithdraw}
                    type={"private"}
                    Layout={Layout}
                  />
                ) : (
                  ""
                )}
              </Switch>
              <Route exact path="*" component={NotFound} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
// export default dashboardRoutes;
