import React, { Component, Fragment } from "react";
import ReactDatatable from '@ashvin27/react-datatable';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import compoents
import { withRouter } from 'react-router-dom';
import P2PPairAddModal from "../partials/P2PPairAddModal";
import PairUpdateModal from "../partials/P2PPairUpdate"


//import action
import { P2PPairList } from '../../actions/tradePairAction'
import { currencyListSelect } from '../../actions/currency'

class Pairmanagement extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "first_currency",
                text: "Base Currency",
                className: "firstCurrencySymbol",
                align: "left",
                sortable: false,
                width: 200
            },
            {
                key: "second_currency",
                text: "Quote Currency",
                className: "secondCurrencySymbol",
                align: "left",
                sortable: false,
                width: 200
            },
            {
                key: "transactionfee",
                text: "Transaction Fee",
                className: "Transaction Fee",
                align: "left",
                sortable: false,
                width: 200
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: false,
                width: 200,
                cell: record => {
                    if (record.status == 0)
                        var type = "Active"
                    else
                        var type = "Deactive"
                    return (
                        <span>{type}</span>
                    );
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-spot-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button>

                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Perpetual",
            no_data_text: 'No Contracts found!',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            addFormModal: false,
            editFormModal: false,
            currencyOptions: [],
            editRecord: {},
            loader: "",
            page: 1,
            limit: 10,
            count: 0,

        };


        this.getP2PPairData = this.getP2PPairData.bind(this);
        this.handleCloseAddForm = this.handleCloseAddForm.bind(this);
        this.handleCloseEditForm = this.handleCloseEditForm.bind(this);
        this.handlePagination = this.handlePagination.bind(this)
        this.getCurrencyData = this.getCurrencyData.bind(this)


    }

    componentDidMount() {
        const { page, limit } = this.state;
        let reqData = {
            page,
            limit
        }
        this.getP2PPairData(reqData);
        this.getCurrencyData()


    };


    async getP2PPairData(reqData) {

        try {
            this.setState({ 'loader': true })

            const { status, loading, result } = await P2PPairList(reqData);
            console.log("resultresultresultresultresult", result)
            this.setState({ 'loader': loading })
            if (status == 'success') {
                this.setState({ "count": result.count, 'records': result.data })
            }
        } catch (err) { }

    }

    async getCurrencyData() {

        const { result, status } = await currencyListSelect();


        if (status == "success") {
            var currencyarray = [];

            result.data.map((item, i) => {
                const id = item._id;
                const label = item.currencySymbol;
                const type = item.type;
                const obj = { 'value': id, 'label': label, 'type': type };
                currencyarray.push(obj);
                this.setState({ currencyOptions: currencyarray });

            });
        }
    }

    editRecord(record) {
        this.setState({
            editFormModal: true,
            editRecord: record
        })
    }
    addRecord() {
        this.setState({ addFormModal: true })
    }

    handleCloseAddForm() {
        this.setState({ addFormModal: false })
    }

    handleCloseEditForm() {
        this.setState({ editFormModal: false })
    }

    handlePagination(index) {
        let reqData = {
            page: index.page_number,
            limit: index.page_size,
            search: index.filter_value
        }
        this.getP2PPairData(reqData);
        this.setState({ page: index.page_number, limit: index.page_size, search: index.filter_value })
    }



    render() {
        const { addFormModal, editRecord, editFormModal, currencyOptions } = this.state;

        return (
            <>

                <P2PPairAddModal
                    isShow={addFormModal}
                    onHide={this.handleCloseAddForm}
                    currencyOptions={currencyOptions}
                    fetchData={this.getP2PPairData}
                />
                <PairUpdateModal

                    isShow={editFormModal}
                    onHide={this.handleCloseEditForm}
                    currencyOptions={currencyOptions}
                    fetchData={this.getP2PPairData}
                    record={editRecord}
                />
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <button onClick={() => this.addRecord()} className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-spot-modal"><FontAwesomeIcon icon={faPlus} /> Add Pair Page</button>
                        <h3 className="mt-2 text-secondary">P2P Trade Pairs</h3>
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                            onChange={this.handlePagination}
                        />
                    </div>
                </div>

            </>

        );
    }

}


export default withRouter(Pairmanagement);
