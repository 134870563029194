import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateLaunchpad,rejectLaunchpad } from "../../actions/LaunchpadActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import Select from "react-select";
import $ from 'jquery';
import { Editor } from '@tinymce/tinymce-react';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import isEmpty from '../../lib/isEmpty';
import fileObjectUrl from '../../lib/fileObjectUrl';
import config from '../../config';


class LaunchpadUpdateModal extends React.Component {
    constructor(props) {
        super(props);
        $("#update-launchpad-modal").find(".text-danger").hide();
        this.state = {
            Name: this.props.record.Name,
            country: this.props.record.country,
            state: this.props.record.state,
            city: this.props.record.city,
            postalCode: this.props.record.postalCode,
            email: this.props.record.email,
            contractAddress: this.props.record.contractAddress,
            decimals: this.props.record.decimals,
            id: this.props.record._id,
            tokenName: this.props.record.tokenName,
            symbol: this.props.record.symbol,
            availableCurrency: this.props.record.availableCurrency,
            price: this.props.record.price,
            minAmt: this.props.record.minAmt,
            discount: this.props.record.discount,
            // availableSale: this.props.record.availableSale,
            maxSupply: this.props.record.maxSupply,
            industry: this.props.record.industry,
            website: this.props.record.website,
            image: this.props.record.image,
            whitePaper: this.props.record.whitePaper,
            content: this.props.record.content,
            startDate: new Date(this.props.record.startDate),
            endDate: new Date(this.props.record.endDate),
            imageUrl: this.props.record.image,
            whitePaperUrl: this.props.record.whitePaper,
            telegramLink: this.props.record.telegramLink,
            twitterLink: this.props.record.twitterLink,
            linkedinLink: this.props.record.linkedinLink,
            redditLink: this.props.record.redditLink,
            youtubeLink: this.props.record.youtubeLink,
            instagramLink: this.props.record.instagramLink,
            facebookLink: this.props.record.facebookLink,
            status: this.props.record.status,
            tokenStatus: this.props.record.tokenStatus,
            errors: {},
        };
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);
    }
    handleChange(date) {
        this.setState({
            startDate: date
        })

    }
    handleChange1(date1) {
        // console.log(typeof date1,'dateTypedateTypedateType',date1)
        this.setState({
            endDate: date1
        })
    }
    handleEditorChange(content, editor) {
        this.setState({ content });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                Name: nextProps.record.Name,
                country: nextProps.record.country,
                state: nextProps.record.state,
                city: nextProps.record.city,
                postalCode: nextProps.record.postalCode,
                email: nextProps.record.email,
                contactAddress: nextProps.record.contactAddress,
                decimals: nextProps.record.decimals,
                id: nextProps.record._id,
                tokenName: nextProps.record.tokenName,
                symbol: nextProps.record.symbol,
                availableCurrency: nextProps.record.availableCurrency ? nextProps.record.availableCurrency : "",
                price: nextProps.record.price,
                minAmt: nextProps.record.minAmt,
                discount: nextProps.record.discount,
                contractAddress: nextProps.record.contractAddress,
                maxSupply: nextProps.record.maxSupply,
                industry: nextProps.record.industry,
                website: nextProps.record.website,
                image: nextProps.record.image,
                whitePaper: nextProps.record.whitePaper,
                telegramLink: nextProps.record.telegramLink,
                twitterLink: nextProps.record.twitterLink,
                linkedinLink: nextProps.record.linkedinLink,
                redditLink: nextProps.record.redditLink,
                youtubeLink: nextProps.record.youtubeLink,
                status: nextProps.record.status,
                tokenStatus: nextProps.record.tokenStatus,
                content: nextProps.record.content,
                instagramLink: nextProps.record.instagramLink,
                facebookLink: nextProps.record.facebookLink,
                startDate: nextProps.record.startDate != "" ? new Date(nextProps.record.startDate) : new Date(),
                endDate: nextProps.record.endDate != "" ? new Date(nextProps.record.endDate) : new Date(),
                imageUrl: config.API_URL + "/currency/" + nextProps.record.image,
                whitePaperUrl: config.API_URL + "/images/launchpad/whitePaper/" + nextProps.record.whitePaper,
            })
        }
        if (nextProps.errors) {
            $("#update-launchpad-modal").find(".text-danger").show();
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.updatetemplate !== undefined
            && nextProps.auth.updatetemplate.data !== undefined
            && nextProps.auth.updatetemplate.data.retMsg !== undefined
        ) {
            $('#update-launchpad-modal').modal('hide');
            toast(nextProps.auth.updatetemplate.data.retMsg, {
                position: toast.POSITION.TOP_CENTER
            });
            nextProps.auth.updatetemplate = "";
        }
    }

    onChange = e => {
        e.preventDefault()
        $("#update-launchpad-modal").find(".text-danger").show();
        // if (e.target.id === 'template-update-tokenName') {
        //     this.setState({ tokenName: e.target.value });
        // }
        //  if (e.target.id === 'template-update-symbol') {
        //     this.setState({ symbol: e.target.value });
        // }
        //  if (e.target.id === 'template-update-availableCurrency') {
        //     this.setState({ availableCurrency: e.target.value });
        // }
        //   if (e.target.id === 'template-update-price') {
        //     this.setState({ price: e.target.value });
        // }
        //   if (e.target.id === 'template-update-minAmt') {
        //     this.setState({ minAmt: e.target.value });
        // }
        //   if (e.target.id === 'template-update-discount') {
        //     this.setState({ discount: e.target.value });
        // }
        //  if (e.target.id === 'template-update-availableSale') {
        //     this.setState({ availableSale: e.target.value });
        // }
        //  if (e.target.id === 'template-update-maxSupply') {
        //     this.setState({ maxSupply: e.target.value });
        // }
        //  if (e.target.id === 'template-update-industry') {
        //     this.setState({ industry: e.target.value });
        // }

        //  if (e.target.id === 'template-update-website') {
        //     this.setState({ website: e.target.value });
        // }
        //   if (e.target.id === 'template-update-startdate') {
        //     this.setState({ startdate: e.target.value });
        // }

        // if (e.target.id === 'template-update-enddate') {
        //     this.setState({ enddate: e.target.value });
        // }

        let cleareError={...this.state.errors,[e.target.id]:""}
        this.setState({errors:cleareError})
        this.setState({ [e.target.id]: e.target.value });
    };

    handleChangeefile = (event) => {
        if (event.target.files[0]) {
            var filesize = event.target.files[0].size
            this.setState({
                curimage: URL.createObjectURL(event.target.files[0]),
                [event.target.name]: event.target.files[0],
            });
        }

        if (event.target.name == "image") {
            this.setState({ imageUrl: fileObjectUrl(event.target.files[0]) })
        } else if (event.target.name == "whitePaper") {
            this.setState({ whitePaperUrl: fileObjectUrl(event.target.files[0]) })
        }
    };


    handleOnChange = (selectedOption) => {
        if (selectedOption && selectedOption.length > 0) {
            this.setState({
                "availableCurrency": selectedOption.map((el) => { return el.value; })
            })
        } else {
            this.setState({ "availableCurrency": [] })
        }
    };

    handleReject = async (e) => {
        e.preventDefault();
        let data={
            id:this.state.id
        }
        let { status, message, error } = await rejectLaunchpad(data)

        if (status) {
            toast(message, {
                position: toast.POSITION.TOP_CENTER
            });
            $('#update-launchpad-modal').modal('hide');
            this.setState({
                errors: {}
            });
            this.props.getData()
        } else if (!status && error) {

            this.setState({
                errors: error
            });
        } else {
            toast(message, {
                position: toast.POSITION.TOP_CENTER
            });
        }

    }
    onlaunchpadUpdate = async (e) => {
        e.preventDefault();
        $("#update-launchpad-modal").find(".text-danger").show();

        const data = new FormData();
        data.append("_id", this.state.id);
        data.append("startDate", this.state.startDate);
        data.append("endDate", this.state.endDate);
        data.append("tokenName", this.state.tokenName);
        data.append("symbol", this.state.symbol);
        data.append("availableCurrency", this.state.availableCurrency.toString());
        data.append("price", this.state.price);
        data.append("minAmt", this.state.minAmt);
        data.append("discount", this.state.discount);
        // data.append("availableSale", this.state.availableSale);
        data.append("maxSupply", this.state.maxSupply);
        data.append("industry", this.state.industry);
        data.append("website", this.state.website);
        data.append("content", this.state.content);
        data.append("image", this.state.image);
        data.append("whitePaper", this.state.whitePaper);
        data.append("telegramLink", this.state.telegramLink);
        data.append("twitterLink", this.state.twitterLink);
        data.append("linkedinLink", this.state.linkedinLink);
        data.append("redditLink", this.state.redditLink);
        data.append("youtubeLink", this.state.youtubeLink);
        data.append("facebookLink", this.state.facebookLink);
        data.append("instagramLink", this.state.instagramLink);
        data.append("decimals", this.state.decimals);
        data.append("contractAddress", this.state.contractAddress);
        data.append("type", 'edit');
        let { status, message, error } = await updateLaunchpad(data)

        if (status) {
            toast(message, {
                position: toast.POSITION.TOP_CENTER
            });
            $('#update-launchpad-modal').modal('hide');
            this.setState({
                errors: {}
            });
            this.props.getData()
        } else if (!status && error) {

            this.setState({
                errors: error
            });
        } else {
            toast(message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    };

    componentDidMount() {
        console.log(this.props.currencyOptions, 'this.state.availableCurrency')
    }

    render() {
        const { errors } = this.state;

        return (
            <div>
                <div className="modal fade" id="update-launchpad-modal"  data-keyboard="false" data-backdrop="static">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Launchpad</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onlaunchpadUpdate} id="update-launchpad" >
                                    {this.state.Name &&
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="tokenName">Name</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.Name}
                                                    id="Name"
                                                    type="text"
                                                    error={errors.Name}
                                                    className={classnames("form-control", {
                                                        invalid: errors.Name
                                                    })} />
                                                <span className="text-danger">{errors.Name}</span>

                                            </div>
                                        </div>}
                                    {this.state.email &&
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="tokenName">Email</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.email}
                                                    id="email"
                                                    type="text"
                                                    error={errors.email}
                                                    className={classnames("form-control", {
                                                        invalid: errors.email
                                                    })} />
                                                <span className="text-danger">{errors.email}</span>

                                            </div>
                                        </div>}
                                    {this.state.country &&
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="country">country</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.country}
                                                    id="country"
                                                    type="text"
                                                    error={errors.country}
                                                    className={classnames("form-control", {
                                                        invalid: errors.country
                                                    })} />
                                                <span className="text-danger">{errors.country}</span>

                                            </div>
                                        </div>}
                                    {this.state.state &&
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="state">state</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.state}
                                                    id="state"
                                                    type="text"
                                                    error={errors.state}
                                                    className={classnames("form-control", {
                                                        invalid: errors.state
                                                    })} />
                                                <span className="text-danger">{errors.state}</span>

                                            </div>
                                        </div>}
                                    {this.state.city &&
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="city">city</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.city}
                                                    id="city"
                                                    type="text"
                                                    error={errors.city}
                                                    className={classnames("form-control", {
                                                        invalid: errors.city
                                                    })} />
                                                <span className="text-danger">{errors.city}</span>

                                            </div>
                                        </div>}
                                    {this.state.postalCode &&
                                        <div className="row mt-2">
                                            <div className="col-md-3">
                                                <label htmlFor="postalCode">postalCode</label>
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.postalCode}
                                                    id="postalCode"
                                                    type="text"
                                                    error={errors.postalCode}
                                                    className={classnames("form-control", {
                                                        invalid: errors.postalCode
                                                    })} />
                                                <span className="text-danger">{errors.postalCode}</span>

                                            </div>
                                        </div>}

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenName">Token Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.tokenName}
                                                id="tokenName"
                                                type="text"
                                                error={errors.tokenName}
                                                className={classnames("form-control", {
                                                    invalid: errors.tokenName
                                                })} />
                                            <span className="text-danger">{errors.tokenName}</span>

                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenName">Contract Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.contractAddress}
                                                id="contractAddress"
                                                type="text"
                                                error={errors.contractAddress}
                                                className={classnames("form-control", {
                                                    invalid: errors.contractAddress
                                                })} />
                                            <span className="text-danger">{errors.contractAddress}</span>

                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenName">Decimals</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.decimals}
                                                id="decimals"
                                                type="text"
                                                error={errors.decimals}
                                                className={classnames("form-control", {
                                                    invalid: errors.decimals
                                                })} />
                                            <span className="text-danger">{errors.decimals}</span>

                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symbol">Token Symbol</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.symbol}
                                                error={errors.symbol}
                                                id="symbol"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.symbol
                                                })}
                                            />
                                            <span className="text-danger">{errors.symbol}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symbol">Available Currency</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Select
                                                value={this.props.currencyOptions && this.props.currencyOptions.length > 0 ? this.props.currencyOptions.filter((el) => {
                                                    if (this.state.availableCurrency.includes(el.value)) {
                                                        return el;
                                                    }
                                                }) : []}
                                                placeholder={'Select'}
                                                onChange={this.handleOnChange}
                                                options={this.props.currencyOptions}
                                                isMulti
                                            />
                                            <span className="text-danger">{errors.availableCurrency}</span>
                                        </div>
                                    </div>
                                    {/*<div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="symbol">Available Currency</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.availableCurrency}
                                                error={errors.availableCurrency}
                                                id="availableCurrency"
                                                type="availableCurrency"
                                                className={classnames("form-control", {
                                                    invalid: errors.availableCurrency
                                                })}
                                            />
                                            <span className="text-danger">{errors.availableCurrency}</span>
                                        </div>
                                    </div>*/}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="minimum">Token ICON</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                type="file"
                                                accept="image/x-png,image/gif,image/jpeg"
                                                onChange={this.handleChangeefile}
                                                name="image"
                                                id="exampleInputFile"
                                                aria-describedby="fileHelp"
                                            />
                                            <span className="text-danger">{errors.image}</span>
                                            <img src={this.state.imageUrl} style={{ 'width': '106px' }} />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="minimum">White Paper</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                type="file"
                                                accept="image/x-pdf,image/doc,image/odt"
                                                onChange={this.handleChangeefile}
                                                name="whitePaper"
                                                id="exampleInputFile"
                                                aria-describedby="fileHelp"
                                            />
                                            <span className="text-danger">{errors.whitePaper}</span>
                                            {!isEmpty(this.state.whitePaperUrl) &&
                                                <a target="_blank" href={this.state.whitePaperUrl}>view</a>
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="price">Token Launch Price (USD)</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.price}
                                                error={errors.price}
                                                id="price"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.price
                                                })}
                                            />
                                            <span className="text-danger">{errors.price}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Minimum Purchase Amount</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.minAmt}
                                                id="minAmt"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.minAmt
                                                })}
                                            />
                                            <span className="text-danger">{errors.minAmt}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Discount</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.discount}
                                                id="discount"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.discount
                                                })}
                                            />
                                            <span className="text-danger">{errors.discount}</span>
                                        </div>
                                    </div>
                                    {/*<div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Token Available to Sale</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.availableSale}
                                                id="availableSale"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.availableSale
                                                })}
                                            />
                                            <span className="text-danger">{errors.availableSale}</span>
                                        </div>
                                    </div>*/}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Token Max Supply</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maxSupply}
                                                id="maxSupply"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.maxSupply
                                                })}
                                            />
                                            <span className="text-danger">{errors.maxSupply}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Industry</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.industry}
                                                id="industry"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.industry
                                                })}
                                            />
                                            <span className="text-danger">{errors.industry}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Website</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.website}
                                                id="website"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.website
                                                })}
                                            />
                                            <span className="text-danger">{errors.website}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Start Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            <DatePicker
                                                selected={this.state.startDate}
                                                onChange={this.handleChange}
                                                dateFormat="MMMM d, yyyy"
                                                className={classnames("form-control", {
                                                    invalid: errors.startDate
                                                })}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                showTimeSelect
                                            />
                                            <span className="text-danger">{errors.startDate}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">End Date</label>
                                        </div>
                                        <div className="col-md-9">
                                            <DatePicker
                                                selected={this.state.endDate}
                                                onChange={this.handleChange1}
                                                dateFormat="MMMM d, yyyy"
                                                className={classnames("form-control", {
                                                    invalid: errors.endDate
                                                })}
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                showTimeSelect
                                            />
                                            <span className="text-danger">{errors.endDate}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="content">Content</label>
                                        </div>
                                        <div className="col-md-9">
                                            <Editor apiKey='5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5'
                                                initialValue="<p>This is the initial content of the editor</p>"
                                                value={this.state.content} onEditorChange={this.handleEditorChange}
                                                init={{
                                                    height: 500,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar:
                                                        'undo redo code | formatselect | bold italic backcolor | \
                                               alignleft aligncenter alignright alignjustify | \
                                               bullist numlist outdent indent | removeformat | help'
                                                }}
                                            />
                                            <span className="text-danger">{errors.content}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Telegram Link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.telegramLink}
                                                id="telegramLink"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.telegramLink
                                                })}
                                            />
                                            <span className="text-danger">{errors.telegramLink}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Twitter Link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.twitterLink}
                                                id="twitterLink"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.twitterLink
                                                })}
                                            />
                                            <span className="text-danger">{errors.twitterLink}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Linkedin Link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.linkedinLink}
                                                id="linkedinLink"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.linkedinLink
                                                })}
                                            />
                                            <span className="text-danger">{errors.linkedinLink}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Reddit Link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.redditLink}
                                                id="redditLink"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.redditLink
                                                })}
                                            />
                                            <span className="text-danger">{errors.redditLink}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Youtube Link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.youtubeLink}
                                                id="youtubeLink"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.youtubeLink
                                                })}
                                            />
                                            <span className="text-danger">{errors.youtubeLink}</span>
                                        </div>

                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">FaceBook Link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.facebookLink}
                                                id="facebookLink"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.facebookLink
                                                })}
                                            />
                                            <span className="text-danger">{errors.facebookLink}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Instagram Link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.instagramLink}
                                                id="instagramLink"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.instagramLink
                                                })}
                                            />
                                            <span className="text-danger">{errors.instagramLink}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                {this.state.status != "request" ?
                                    <button
                                        form="update-launchpad"
                                        type="submit"
                                        className="btn btn-primary">
                                        Update Launchpad
                                    </button> :
                                    <> <button
                                        form="update-launchpad"
                                        type="button"
                                        onClick={this.handleReject}
                                        className="btn btn-primary">
                                        Reject
                                    </button>
                                        <button
                                            form="update-launchpad"
                                            type="submit"
                                            className="btn btn-primary">
                                            Approve
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

LaunchpadUpdateModal.propTypes = {
    updateLaunchpad: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateLaunchpad }
)(withRouter(LaunchpadUpdateModal));
