import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";

import $ from "jquery";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { toast, ToastContainer } from "react-toastify";
import keys from "../../actions/config";

// import component
import { withRouter } from 'react-router-dom';
// import CmsAddModal from "../partials/CmsAddModal";
import LaunchpadCmsUpdateModal from "../partials/LunchpadCmsUpdateModal.jsx";

// import action
import { getLaunchpadCmsList } from "../../actions/cmsActions";

const url = keys.baseUrl;
class LaunchpadCmsPage extends Component {
    constructor(props) {
        super(props);
        function unfocus() {
            $("*:focus").blur();
        }
        this.columns = [
            {
                key: "identifier",
                text: "Page name",
                className: "identifier",
                align: "left",
                sortable: false,
            },
            {
                key: "status",
                text: "Status",
                className: "identifier",
                align: "left",
                sortable: false,
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: (record) => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-lanchpadcms-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: "5px" }}
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            {/* <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button> */}
                        </Fragment>
                    );
                },
            },
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Cms",
            no_data_text: "No CMS found!",
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last",
                },
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            loader: false,
            records: [],
            addFormModal: false,
            editFormModal: false,
            editRecord: {},
        };

        this.fetchCmsData = this.fetchCmsData.bind(this);
        this.handleCloseEditForm = this.handleCloseEditForm.bind(this);
        this.handleCloseAddForm = this.handleCloseAddForm.bind(this);
    }

    componentDidMount() {
        this.fetchCmsData();
    }

    async fetchCmsData() {
        try {
            this.setState({ loader: true });
            const { status, loading, result } = await getLaunchpadCmsList();
            this.setState({ loader: loading });
            if (status == "success") {
                this.setState({ records: result });
            }
        } catch (err) { }
    }

    addRecord() {
        this.setState({ addFormModal: true });
    }

    editRecord(record) {
        // this.setState({
        //     editFormModal: true,
        //     editRecord: record
        // })

        $("#update-lanchpadcms-modal").find(".text-danger").hide();
        console.log("recorxs", record);
        this.setState({ editRecord: record });
    }

    handleCloseEditForm() {
        this.setState({ editFormModal: false, editRecord: {} });
    }

    deleteRecord(record) {
        console.log(record);
        axios
            .post(url + "api/cms-delete", { _id: record._id })
            .then((res) => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            })
            .catch();
        this.fetchCmsData();
    }

    handleCloseAddForm() {
        this.setState({ addFormModal: false });
    }

    render() {
        const { addFormModal, editFormModal, editRecord } = this.state;

        return (
            <>

                {/* <CmsAddModal
                        isShow={addFormModal}
                        onHide={this.handleCloseAddForm}
                        fetchData={this.fetchLanguage}
                    /> */}
                <LaunchpadCmsUpdateModal
                    // isShow={editFormModal}
                    // onHide={this.handleCloseEditForm}
                    // fetchData={this.fetchCmsData}
                    record={editRecord}
                />
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        {/* <button onClick={() => this.addRecord()} className="btn btn-outline-primary float-right mt-3 mr-2" > Add CMS</button> */}

                        <h3 className="mt-2 text-secondary">
                            LaunchPad Cms List
                        </h3>
                        <ReactDatatable
                            config={this.config}
                            records={this.state.records}
                            columns={this.columns}
                        />
                    </div>
                </div>
                <ToastContainer />
            </>

        );
    }
}

export default withRouter(LaunchpadCmsPage);