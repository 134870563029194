import React from "react";
import classnames from "classnames";
import { Modal } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import $ from "jquery";
// import action
import { updateCms } from "../../actions/cmsActions";

// import lib
import { toastAlert } from "../../lib/toastAlert";
import { CKEditor } from "ckeditor4-react";
import keys from "../../config/index";
import config from '../../config';
import axios from "axios";
const url = keys.API_URL;
const initialFormValue = {
  id: "",
  identifier: "",
  title: "",
  content: "",
  image: "",
  metatitle: "",
  metadescription: "",
  metakeywords: "",
};

class CmsUpdateModal extends React.Component {
  constructor(props) {
    super();
    this.state = {
      formValue: initialFormValue,
      errors: {},
    };
    // $("#update-cmsedit-modal").find(".text-danger").hide();
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { record } = nextProps;
    if (record) {
      this.setState({
        formValue: {
          id: record._id,
          identifier: record.identifier,
          title: record.title,
          content: record.content,
          image: record.image,
          metatitle: record.metatitle,
          metadescription: record.metadescription,
          metakeywords: record.metakeywords,
        },
      });
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let formData = { ...this.state.formValue, ...{ [name]: value } };
    this.setState({ formValue: formData });
  };

  handleEditorChange(content, editor) {
    console.log("contentcontent",content);
      let formData = { ...this.state.formValue, ...{ 'content': content } };
      this.setState({ formValue: formData });
      
  }

  // handleEditorChange(e) {
  //   let formData = {
  //     ...this.state.formValue,
  //     ...{ content: e.editor.getData() },
  //   };
  //   this.setState({ formValue: formData });
  // }

  handleFileChange = (event) => {
    this.setState({ image: event.target.files });
  };

  handleClose = () => {
    const { onHide } = this.props;
    // onHide();
    this.setState({ formValue: initialFormValue, errors: {} });
    $(".modal-backdrop").hide();
    $("#update-cmsedit-modal").hide();

  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      id,
      identifier,
      title,
      content,
      image,
      metakeywords,
      metatitle,
      metadescription,
    } = this.state.formValue;
    const { fetchData } = this.props;

    const formData = new FormData();
    formData.append("id", id);
    formData.append("identifier", identifier);
    formData.append("title", title);
    formData.append("content", content);
    formData.append("metatitle", metatitle);
    formData.append("metadescription", metadescription);

    formData.append("metakeywords", metakeywords);

    // for (const key of Object.keys(image)) {
    //     formData.append('file', image[key])
    // }

    let reqData = {
      id,
      identifier,
      title,
      content,
      metatitle,
      metadescription,
      metakeywords,
    };

    const { status, loading, message, error } = await updateCms(reqData);
    if (status == "success") {
      toastAlert("success", message, "updateCms");
      fetchData();
      this.handleClose();
    } else {
      if (error) {
        this.setState({ errors: error });
      }
      toastAlert("error", message, "updateCms");
    }
  };
   handleBeforeLoad = (CKEDITOR) => {
    CKEDITOR.disableAutoInline = true;
    CKEDITOR.on('instanceReady', (ev) => {
      const editor = ev.editor;
      editor.setData(this.state.formValue.content);
    });
  };
  render() {
    const {
      identifier,
      title,
      content,
      metakeywords,
      metadescription,
      metatitle,
    } = this.state.formValue;
    const { errors } = this.state;
    const { isShow } = this.props;
    return (
      <div>
        <div className="modal fade" id="update-cmsedit-modal" data-keyboard="false" data-backdrop="static">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Cms</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form noValidate onSubmit={this.onCmsUpdate} id="update-cms">
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="name">Identifier</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.handleChange}
                        value={identifier}
                        name="identifier"
                        type="text"
                        error={errors.identifier}
                        className={classnames("form-control", {
                          invalid: errors.identifier,
                        })}
                        readOnly
                      />
                      <span className="text-danger">{errors.identifier}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Page Name</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.handleChange}
                        value={title}
                        error={errors.title}
                        name="title"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.title,
                        })}
                      />
                      <span className="text-danger">{errors.title}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="content">Content</label>
                    </div>
                    <div className="col-md-9">
                      <Editor apiKey='5vk89nvvi2zckrb2lp2ctyyolewhq1v3pzdiwb7at68h40a5'
                                        // initialValue={this.state.formValue.content}
                                        value={content}
                                        onEditorChange={this.handleEditorChange}
                                        init={{
                                          height: 500,
                                          menubar: false,
                                          plugins: [
                                              'advlist autolink lists link image charmap print preview anchor',
                                              'searchreplace visualblocks code fullscreen',
                                              'insertdatetime media table paste code help wordcount'
                                          ],
                                          menubar:
                                          "file edit view insert format tools table tc help",
              
                                          toolbar:
                                          'undo redo code | formatselect | bold italic backcolor | \
                                         alignleft aligncenter alignright alignjustify | \
                                         bullist numlist outdent indent | removeformat | help',
                                     
                                         images_upload_handler: function (
                                          blobInfo,
                                          success,
                                          failure
                                        ) {
                                          let data = new FormData();
                                          data.append(
                                            "file",
                                            blobInfo.blob(),
                                            blobInfo.filename()
                                          );
                                          axios
                                            .post(url + "/api/tinyimgupload", data)
              
                                            .then(function (res) {
                                              console.log(res, "kr resss");
                                              success(
                                                url + "/images/tinymce/" + res.data.result
                                              );
                                            })
                                            .catch(function (err) {
                                              failure("HTTP Error: " + err.message);
                                            });
                                        },
                                        menubar:
                            "file edit view insert format tools table tc help",

                            toolbar:
                            'undo redo code | formatselect | bold italic backcolor | \
                           alignleft aligncenter alignright alignjustify | \
                           bullist numlist outdent indent | removeformat | help'
                                        }}
                                    />

                      {/* <CKEditor
                      initData={content}
               onBeforeLoad={this.handleBeforeLoad}

                        config={{
                          extraAllowedContent: "div(*)",
                          allowedContent: true,
                          height: 500,
                        }}
                        
                        
                        onChange={this.handleEditorChange}
                      /> */}



                      <span className="text-danger">{errors.content}</span>
                    </div>
                  </div>

                  {/* <div className="row mt-2">
                                <div className="col-md-3">
                                    <br />
                                    <label htmlFor="image">Image</label>
                                </div>
                                <div className="col-md-9">
                                    <input type="file" multiple onChange={this.handleFileChange}
                                    />

                                </div>
                            </div> */}

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Meta Title</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.handleChange}
                        value={metatitle}
                        error={errors.metatitle}
                        name="metatitle"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.metatitle,
                        })}
                      />
                      <span className="text-danger">{errors.metatitle}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Meta Description</label>
                    </div>
                    <div className="col-md-9">
                      <textarea
                        onChange={this.handleChange}
                        value={metadescription}
                        error={errors.metadescription}
                        name="metadescription"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.metadescription,
                        })}
                      />
                      <span className="text-danger">{errors.metadescription}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="identifier">Meta Keywords</label>
                    </div>
                    <div className="col-md-9">
                      <textarea
                        onChange={this.handleChange}
                        value={metakeywords}
                        error={errors.metakeywords}
                        name="metakeywords"
                        type="text"
                        className={classnames("form-control", {
                          invalid: errors.metakeywords,
                        })}
                      />
                      <span className="text-danger">{errors.metakeywords}</span>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.handleClose}
                  // data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  onClick={this.handleSubmit}
                  type="submit"
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default CmsUpdateModal;
